import { FunctionComponent } from 'react'
import { Button, Box, Typography, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Skeleton from 'react-loading-skeleton'
import colors from '../../colors'
import { Link } from 'react-router-dom'
import { Check } from 'react-feather'
import { SPACING } from '../../constants'

type IntegrationCardProps = {
  title: string,
  bodyText: string,
  backgroundImagePath: string,
  connectHandler: Function,
  connected: boolean,
  editPath?: string,
  loading?: boolean,
}


const IntegrationCard: FunctionComponent<IntegrationCardProps> = ({ title, bodyText, backgroundImagePath, connectHandler, connected, editPath, loading }) => {
  const styles = useStyles({ backgroundImagePath })
  const ConnectButton = () => {
    if (connected) {
      return (
        <Button disableElevation style={{ backgroundColor: colors.GREEN, color: colors.WHITE }} startIcon={<Check size={20}/>}>
          Connected
        </Button>
      )
    }
    return (
      <Box>
        <Button onClick={() => connectHandler()}>Connect</Button>
      </Box>
    )
  }

  const EditButton = () => {
    if (!editPath || !connected) {
      return null
    }

    return (
      <Link to={editPath}>
        <Button variant={"text"}>Edit Connection</Button>
      </Link>
    )
  }
  return (
    <Box className={styles.container}>
      <img alt={title} src={backgroundImagePath} className={styles.mediaContainer}/>
      <Box className={styles.detailsContainer}>
        <Box className={styles.titleContainer}>{title}</Box>
        <Box className={styles.textContainer}>
          <Typography>{bodyText}</Typography>
        </Box>
        {
          loading
          ? <Skeleton width={180} height={40}/>
          :  <>
              <Box mr={1} display="inline-block">
                <ConnectButton />
              </Box>
              <EditButton />
            </>
      }
      </Box>
    </Box>
  )
}

const useStyles = makeStyles<Theme, { backgroundImagePath: string }>((theme) => ({
  container: {
    width: 372,
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column'
  },
  mediaContainer: {
    width: 372,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 372,
  },
  detailsContainer: {
    padding: SPACING * 2,
  },
  titleContainer: {
    fontWeight: 'bold',
    color: colors.STITCH_BLACK,
    marginBottom: SPACING * 2,
  },
  textContainer: {
    marginBottom: SPACING * 2,
  },
}))

export default IntegrationCard
