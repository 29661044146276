import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import SubmenuItem from './SubmenuItem'
import { SubmenuItem as SubmenuItemType } from '../../types'
import { Outlet } from 'react-router-dom'
import colors from '../../colors'

type SubmenuProps = {
  items: SubmenuItemType[],
  basePath: string,
}

export default function Submenu({ items, basePath }: SubmenuProps) {
  const styles = useStyles()

  return (
    <Box className={styles.conatiner}>
      <Box className={styles.keysContainer}>
        <ul>
          {items.map((item) => (
            <li key={item.id}>
              <Link to={basePath + item.subPath}>
                <SubmenuItem item={item} basePath={basePath} />
              </Link>
            </li>
          ))}
        </ul>
      </Box>
      <Box className={styles.contentContainer}>
        <Outlet />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  conatiner: {
    borderRadius: 10,
    maxHeight: '100%',
    flex: '1 1 auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    display: 'flex',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  contentContainer: {
    flex: 1,
    background: colors.WHITE,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    }
  },
  keysContainer: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    background: colors.WHITE,
    borderRight: `1px solid ${colors.GREY_5}`,
    width: 250
  },
})