import React, { FunctionComponent } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Box, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Info } from 'react-feather'
import colors from '../../colors'
import { SPACING } from '../../constants'

type MetricCardProps = {
  title: string,
  value: string,
  loading: boolean,
  info?: string,
}

const MetricCard: FunctionComponent<MetricCardProps> = ({ title, value, info, loading }) => {
  const styles = useStyles()

  const InfoIcon: FunctionComponent = () => {
    return (
      <Box className={styles.infoContainer}>
        <Tooltip title={String(info)}>
          <Info size={16} strokeWidth={3} color={colors.GREY_3}/>
        </Tooltip>
      </Box>
    )
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.titleContainer}>
        <Typography variant="inherit">
          {title}
        </Typography>
        {info && <InfoIcon />}
      </Box>
      {
        loading
        ? <Skeleton height={61} width={100} />
        : <Typography variant="h2">
            {value}
          </Typography>
      }
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 12,
    boxShadow: '0px 0px 4px rgba(17, 51, 85, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 210,
    height: 160,
    padding: SPACING * 2,
  },
  titleContainer: {
    marginBottom: SPACING * 3,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: SPACING * 1,
    cursor: 'pointer',
  }
}))

export default MetricCard
