import { createTheme } from '@material-ui/core/styles'
import colors from './colors'

export const defaultTheme = createTheme({
  spacing: 8,
  palette: {
    background: {
      default: colors.WHITE,
    },
    text: {
      primary: colors.STITCH_BLACK,
      disabled: colors.GREY_3,
    },
    primary: {
      main: colors.STITCH_BLUE,
      light: colors.LIGHT_BLUE,
      dark: colors.STITCH_BLUE,
      contrastText: colors.WHITE,
    },
    secondary: {
      main: colors.RED,
      light: colors.LIGHT_RED,
      dark: colors.DARK_RED,
      contrastText: colors.WHITE,
    },
    error: {
      main: colors.RED,
      light: colors.LIGHT_RED,
      dark: colors.DARK_RED,
      contrastText: colors.WHITE,
    },
    warning: {
      main: colors.YELLOW,
      light: colors.LIGHT_YELLOW,
      dark: colors.DARK_YELLOW,
      contrastText: colors.DARK_YELLOW,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '4em',
      fontWeight: 400,
    },
    h2: {
      fontSize: '3em',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.5625em',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.25em',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1em',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1em',
      lineHeight: '1.5em',
      fontWeight: 400,
      color: colors.STITCH_BLACK,
    },
    body2: {
      fontSize: '1em',
      fontWeight: 400,
    },
    button: {
      fontSize: '1em',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.75em',
      fontWeight: 400,
    },
    overline: {
      fontSize: '0.625em',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: '0.3125rem',
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.STITCH_BLUE,
          color: colors.WHITE,
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&$marginDense': {
            transform: 'translate(14px, 14px) scale(1)',
          },
        },
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      }
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderLeft: `3px solid ${colors.STITCH_BLUE}`,
          marginLeft: 11,
          marginBottom: 0,
        },
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        lineVertical: {
          minHeight: 12,
          marginLeft: -1,
          borderLeftWidth: 3,
          borderLeftColor: colors.STITCH_BLUE,
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: colors.GREY_7,
          borderColor: colors.GREY_5,
          '&$error': {
            background: colors.PALE_RED,
            '&$focused': {
              background: colors.GREY_7,
            },
          },
        },
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.BRIGHT_BLUE,
          cursor: 'pointer',
        },
      }
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          marginTop: 16,
        },
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        InputLabelProps: {
          shrink: true,
        },
        margin: 'dense',
      },
      styleOverrides: {
        root: {
          '&:Mui-disabled': {
            fontWeight: 'bold'
          }
        }
      },
    },
  },
})

export default defaultTheme
