import React, { FunctionComponent } from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Search, X } from 'react-feather'

interface SearchBarProps {
  onChange: Function,
  value: string,
  fullWidth: boolean,
}

const SearchBar: FunctionComponent<SearchBarProps> = ({ onChange, value, ...props }) => {
  const styles = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(event.target.value)
  }

  const handleClear = () => {
    onChange('')
  }

  const Icon = () => {
    if (!value) {
      return <Search />
    }

    return (
      <X style={{ cursor: 'pointer' }} onClick={handleClear} />
    )
  }

  return (
    <TextField
      label="Search"
      className={styles.input}
      variant="outlined"
      value={value}
      onChange={handleChange}
      InputProps={{
        endAdornment: <Icon />,
      }}
      {...props}
    />
  )
}

const useStyles = makeStyles({
  input: {
    color: 'green',
    "&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
      color: 'red'
    }
  }
})

export default SearchBar
