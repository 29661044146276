import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useAuthStore } from '../stores/auth'
import colors from '../colors'
import { SPACING } from '../constants'
import {AuthState} from '../types/auth'
import useCurrentAppLocation from '../hooks/useCurrentAppLocation'
import OrgSwitcher from './OrgSwitcher'

const Header = () => {
  const styles = useStyles()
  const location = useCurrentAppLocation()
  const user = useAuthStore((state: AuthState) => state.user)
  const organizationName = useAuthStore((state: AuthState) => state.user && state.user.organization && state.user.organization.name)
  if (!user) { return null }
  return (
    <Box className={styles.container}>
      <Typography variant={'h3'}>
        {location.title}
      </Typography>
      <Box>
        {
          user.role === 'super_admin' ?
          <OrgSwitcher />
          : <Typography>
              {organizationName}
            </Typography>
        }
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    background: colors.PALE_BLUE,
    color: colors.STITCH_BLACK,
    paddingTop: SPACING * 2,
    paddingBottom: SPACING * 2,
    paddingLeft: SPACING * 4,
    paddingRight: SPACING * 4,
    width: '100%',
    height: 60,
  }
}))

export default Header
