import { FunctionComponent, useState, useEffect } from 'react'
import {
  Box,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../../colors'
import { Subscription } from '../../types'
import MembershipBenefits from './MembershipBenefits'
import axios from '../../utils/axios'
import usePatronStore from '../../stores/patron'
import { useParams } from 'react-router-dom'

type Props = {}

const BenefitsView: FunctionComponent<Props> = () => {
  const { id: patronId } = useParams<{ id: string }>()

  const subscriptions = usePatronStore((state) => {
    if (!patronId) return
    const patron = state.patrons[patronId]
    if (!patron) return []
    return patron.subscriptions || []
  })

  const [fetchingBenefits, setFetchingBenefits] = useState(true)
  const [memberships, setMemberships] = useState([])

  useEffect(() => {
    if (!patronId) return

    (async () => {
      const resp = await axios.get(`/patrons/${patronId}/benefits`)

      setFetchingBenefits(false)

      setMemberships(resp.data.memberships)
    })()
  }, [patronId])

  const styles = useStyles()

  if (fetchingBenefits) {
    return (
      <Box>
        loading
      </Box>
    )
  }

  if (!memberships.length) {
    return (
      <Box>
        This patron is not part of any of your memberships
      </Box>
    )
  }

  if (!subscriptions) return null

  return (
    <Box p={4} className={styles.container}>
      <Box mb={4}>
        <Typography variant={"h3"}>Benefits</Typography>
      </Box>
      {subscriptions.map((subscription: Subscription) => {
        return (
          <Box key={subscription.id}>
            <MembershipBenefits key={subscription.id} subscriptionId={subscription.id} />
          </Box>
        )
      })}
    </Box>
  )
}

const useStyles = makeStyles({
  tableContainer: {

  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    width: '80%',
    maxWidth: 400,
  },
  issueButton: {
    background: colors.PALE_BLUE,
  },
  membershipTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  cancelButton: {
    display: 'inline'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  disabled: {
    fontWeight: 'bold'
  },
  dateBox: {
    display: 'flex',
    flexDirection: 'row',
  },
})

export default BenefitsView
