import { FunctionComponent, useState, useEffect } from 'react'
import useAuth from '../stores/auth'
import MetricCard from '../components/Cards/MetricCard'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import axios from '../utils/axios'

const Dashboard: FunctionComponent = () => {
  const styles = useStyles();
  const { logout } = useAuth();

  const [fetchingStats, setFetchingStats] = useState(true)
  const [mrr, setMrr] = useState('')
  const [activePatrons, setActivePatrons] = useState('')
  const [benefitUsage, setBenefitUsage] = useState('')

  useEffect(() => {
    (async () => {

      try {
        const res = await axios.get('/organization/dashboard_stats')

        setMrr(res.data.mrr)
        setActivePatrons(res.data.activeMemberships)
        setBenefitUsage(res.data.benefitUsage)
        setFetchingStats(false)
      } catch (e) {
        if ((e as Error).message.includes("401")) {
          logout();
        }
      }
    })()
  }, [logout])

  return (
    <Box className={styles.metricCardsContainer}>
      <Box mr={2}>
        <MetricCard loading={fetchingStats} title="Total Patrons" value={activePatrons} info={"The number of patrons actively subscribed to any of your memberships"} />
      </Box>
      <Box mr={2}>
        <MetricCard loading={fetchingStats} title="MRR" value={mrr} info={"Monthly Recurring Revenue: Given your current membership enrollment, this is the average membership revenue you can expect to net each month."} />
      </Box>
      <Box>
        <MetricCard loading={fetchingStats} title="Benefit Usage" value={benefitUsage || '-'} info={"The percentage of benefits issued in the last 30 days that have been redeemed."} />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  metricCardsContainer: {
    display: 'flex',
  }
})

export default Dashboard
