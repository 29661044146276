import React, { FunctionComponent } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SubmenuItem as SubmenuItemType } from '../../types'
import colors from '../../colors'
import { SPACING } from '../../constants'
import { useLocation } from 'react-router'


type SubmenuItemProps = {
  item: SubmenuItemType,
  basePath: string,
}

const SubmenuItem: FunctionComponent<SubmenuItemProps> = ({ item, basePath }) => {
  const path = useLocation().pathname
  const isSelected = (basePath + item.subPath).replace(/\/$/, "") === path.replace(/\/$/, "");

  const styles = useStyles(isSelected)
  return (
    <Box className={isSelected ? styles.selectedContainer : styles.container}>
      <Box mr={2}>
        {item.icon && <item.icon />}
      </Box>
      <Typography>{item.title}</Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    color: colors.STITCH_BLACK,
    padding: SPACING * 2,
    background: colors.WHITE,
    borderRight: '5px solid transparent',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  selectedContainer: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    display: 'flex',
    alignItems: 'center',
    background: colors.PALE_BLUE,
    padding: SPACING * 2,
    borderRight: `5px solid ${colors.STITCH_BLUE}`,
  }
}))

export default SubmenuItem
