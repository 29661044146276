import { FunctionComponent, useState, useEffect } from 'react'
import { CircularProgress, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core'
import useAuthStore from '../stores/auth'
import { useNavigate } from 'react-router-dom'
import { Organization } from '../types/auth'

const OrgSwitcher: FunctionComponent = () => {
  const user = useAuthStore((store) => store.user)
  const [orgs, setOrgs] = useState<Organization[]>([])
  const updateOrganization = useAuthStore((store) => store.updateOrganization)
  const fetchAvailableOrganizations = useAuthStore((store) => store.fetchAvailableOrganizations)
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  const handleChange = (event: any) => {
    (async () => {
      setLoading(true)
      await updateOrganization(String(event.target.value))
      navigate(0)
    })()
  }

  useEffect(() => {
    (async () => {
      const orgs = await fetchAvailableOrganizations()
      setOrgs(orgs)
      setLoading(false)
    })()
  }, [fetchAvailableOrganizations])

  if (!user) return null
  const organization = user.organization


  if (loading) {
    return <CircularProgress size={16} />
  }

  return (
    <FormControl size="small" variant="outlined">
      <InputLabel>Organization</InputLabel>
      <Select
        value={String(organization.id)}
        onChange={handleChange}
        label="Organization"
      >
        {orgs.map((org, index) => (
          <MenuItem key={index} value={org.id}>{org.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default OrgSwitcher
