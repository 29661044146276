import { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const DetailOverlayContainer: FunctionComponent = ({ children }) => {
  const styles = useStyles()
  return (
    <Box className={styles.container}>
      {children}
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    background: 'white',
    top: 0,
    left: 0,
  }
})
export default DetailOverlayContainer

