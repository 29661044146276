import { FunctionComponent } from 'react'
import Submenu from '../../components/Submenu'
import {
  Zap
} from 'react-feather'

const Settings: FunctionComponent = () => {
  return (
    <Submenu
      basePath={'/settings'}
      items={[
        {
          id: 'integrations',
          icon: Zap,
          title: 'Integrations',
          subPath: ''
        }
      ]}
    />
  )
}

export default Settings
