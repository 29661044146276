import { FunctionComponent, useState, useEffect } from 'react'
import { MoreVertical } from 'react-feather'
import { Box, Popover, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import usePatronageBenefitStore from '../../stores/patronageBenefit'
import { PatronBenefit } from '../../types'
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks'

type Props = {
  benefit: PatronBenefit,
  handleLoading: (isLoading: boolean) => void,
  benefitCycleId: string,
}

const BenefitActions:FunctionComponent<Props> = ({ benefit, handleLoading, benefitCycleId }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: `ben-popover-${benefit.id}` })
  const redeem = usePatronageBenefitStore((store) => store.redeemBenefit)
  const unredeem = usePatronageBenefitStore((store) => store.unredeemBenefit)
  const destroy = usePatronageBenefitStore((store) => store.destroyBenefit)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const styles = useStyles()

  useEffect(() => {
    handleLoading(isLoading)
  }, [isLoading, handleLoading])

  const redeemBenefit = async () => {
    setIsLoading(true)
    popupState.close()
    await redeem(benefit.uuid)
    setIsLoading(false)
  }

  const unredeemBenefit = async () => {
    setIsLoading(true)
    popupState.close()
    await unredeem(benefit.uuid)
    setIsLoading(false)
  }

  const destroyBenefit = async () => {
    setIsLoading(true)
    popupState.close()
    await destroy(benefit.uuid, benefitCycleId)
    setIsLoading(false)
  }

  return (
    <Box>
      {isLoading ? <CircularProgress size={24}/> : <MoreVertical  {...bindTrigger(popupState)}/> }
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={styles.container} p={2}>
          { benefit.redeemedAt ?
            <Button variant={"text"}onClick={unredeemBenefit}>
              Restore
            </Button>
            :
            <Button variant={"text"}onClick={redeemBenefit}>
              Redeem
            </Button>
          }
          <Button variant={"text"}onClick={destroyBenefit}>
            Delete
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  }
})

export default BenefitActions
