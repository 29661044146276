import axios from 'axios'
import useAuthStore from '../stores/auth'
import applyCaseMiddleware from 'axios-case-converter'

const apiHost = process?.env?.REACT_APP_API_HOST

if (!apiHost) {
  throw new Error('You must define a REACT_APP_API_HOST ENV var')
}


// Altering the camel function for our middlware. We use uuids with - in them, but don't use - anywhere else. The default function, shown here:
// (input.charAt(0).toLowerCase() + input.slice(1)).replace(/[-_](.)/g, (match, group1) => group1.toUpperCase());
// Changes - to _
const options = {
  caseFunctions: {
    camel: (input: any) => {
      return (input.charAt(0).toLowerCase() + input.slice(1)).replace(/[_](.)/g, (_: any, group1: string) => group1.toUpperCase());
    }
  }
};

const stitchAxios = applyCaseMiddleware(axios.create({
  baseURL: apiHost,
}), options)

stitchAxios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    useAuthStore.getState().logout()
    window.location.replace('/login')
  }
  return Promise.reject(error);
});

stitchAxios.interceptors.request.use(
  (config) => {
    const token = useAuthStore.getState().jwt
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export default stitchAxios
