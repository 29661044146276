import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import axios from '../utils/axios'
import { User, AuthState, Organization } from '../types/auth'

const createFunction = (set: Function, _: Function): AuthState => ({
  jwt: undefined,
  user: undefined,
  updateOrganization: async (id: string) => {
    const resp = await axios.post('/super_admin/update_organization', {
      id
    })
    set((state: AuthState) => ({
      ...state,
      user: {
        ...state.user,
        organization: resp.data
      }
    }))

    return resp.data
  },
  fetchAvailableOrganizations: async (): Promise<Organization[]> => {
    try {
      const response = await axios.get('/super_admin/organizations');
      return response.data;
    } catch {
      return [];
    }
  },
  requestPasswordResetToken: async (email: string) => {
    await axios.post('/backend_user/start_password_reset', {
      email,
    })
  },
  checkPasswordResetToken: async (token: string, uuid: string) => {
    try {
      const resp = await axios.get('/backend_user/check_token', {
        params: {
          token,
          uuid
        }
      })
      if (resp.status === 200) return true
    } catch { }

    return false
  },
  resetPassword: async (token: string, uuid: string, password: string, confirmation: string) => {
    const resp = await axios.post('/backend_user/reset_password', {
      token,
      uuid,
      password,
      confirmation
    })
    return resp
  },
  login: async (email: string, password: string): Promise<[boolean, string]> => {
    try {
      const resp = await axios.post('/login', {
        auth: {
          email,
          password
        }
      })
      set({
        jwt: resp.data.jwt,
        user: {
          email: resp.data.email,
          id: resp.data.id,
          role: resp.data.role,
          organization: resp.data.organization,
        }
      })
    } catch (e: unknown) {
      const message = e instanceof Error ? e.message : String(e)

      if (message.includes('404')) {
        return [false, 'Invalid username or password']
      }
      throw e
    }
    return [true, '']
  },
  logout: () => {
    set(() => ({ jwt: '', user: undefined }))
  },
  setUser: (user: User) => {
    set(() => ({ user }))
  },
  setJwt: (jwt: string) => {
    set(() => ({ jwt }))
  },
  rehydrated: false,
  setRehydrated: (isRehydrated: boolean) => set({ rehydrated: isRehydrated })
})

export const useAuthStore = create<AuthState>()(persist(createFunction, {
  name: 'stitchAuth',
  onRehydrateStorage: (state: AuthState) => {
    return ((state?: AuthState | undefined, error?: unknown): void => {
      if (!state) {
        throw error
      }

      state.setRehydrated(true)
    })
  },
}))

export default useAuthStore
