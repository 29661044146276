import { FunctionComponent } from 'react'
import useAuthStore from '../../stores/auth'
import { Link } from 'react-router-dom'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextField } from 'formik-material-ui'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'

type FormProps = {
  uuid: string,
  token: string,
  onSuccess: () => void,
  onError: (message: string) => void
}

const ProfileForm: FunctionComponent<FormProps> = ({ token, uuid, onSuccess, onError }) => {
  const styles = useStyles()
  const { resetPassword } = useAuthStore()
  return (
    <Box p={4} className={styles.container}>
      <Box className={styles.formContainer}>
        <Box className={styles.titleContainer} mb={2}>
          <Typography variant="h3">Reset Password</Typography>
        </Box>
        <Formik
          enableReinitialize
          initialValues={{
            token,
            uuid,
            password: '',
            confirmation: '',
          }}
          onSubmit={async ({ token, uuid, password, confirmation }) => {
            const resp = await resetPassword(
              token,
              uuid,
              password,
              confirmation
            )
            if (resp.status === 200) {
              onSuccess()
              return
            }

            onError(`There was an error processing your request. Status: ${resp.status}`)
          }}
          validationSchema={
            Yup.object({
              password: Yup.string().required('Password is required').max(72, 'Max length is 72 characters'),
              confirmation: Yup.string().oneOf([Yup.ref('password'), null], "Passwords don't match")
            })
          }
        >
          { (formikProps) => {
            return (
              <Form>
                <Box mb={2}>
                  <Field
                    fullWidth
                    required
                    component={TextField}
                    name={'password'}
                    type={'password'}
                    label="New Password"
                  />
                  <Field
                    fullWidth
                    required
                    component={TextField}
                    name={'confirmation'}
                    type={'password'}
                    label="Confirm New Password"
                  />
                </Box>
                <Box className={styles.buttonContainer}>
                  <Box mr={2} className={styles.cancelButton}>
                    <Link to={'/login'}>
                      <Button variant="text">Cancel</Button>
                    </Link>
                  </Box>
                  <Button disabled={formikProps.isSubmitting} type="submit">
                    { formikProps.isSubmitting ? <CircularProgress size={16} style={{ marginRight: 8}}/> : null }
                    Set New Password
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },
  formContainer: {
    width: '90%',
    maxWidth: 400,
  },
  cancelButton: {
    display: 'inline'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  disabled: {
    fontWeight: 'bold'
  }
})

export default ProfileForm
