import { FunctionComponent, useState, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import IntegrationCard from '../../components/Cards/IntegrationCard'
import axios from '../../../src/utils/axios'

const Settings: FunctionComponent = () => {
  const styles = useStyles()
  const [fetching, setFetching] = useState(true)
  const [integrations, setIntegrations] = useState<{ mailchimp?: boolean, stripe?: boolean }>({})

  useEffect(() => {
    (async () => {
      setFetching(true)
      const res = await axios.get('/organization/settings')
      setIntegrations(res.data)
      setFetching(false)
    })()
  }, [])
  return (
    <Box p={2}>
      <Box mb={2}>
        <Typography variant={'h3'}>
          Integrations
        </Typography>
        <Typography variant={'caption'}>
          Integrate Stitch with 3rd-party applications to get user data where it needs to be.
        </Typography>
      </Box>
      <Box className={styles.cardContainer}>
        <Box mr={2}>
          <IntegrationCard
            connected={!!integrations.mailchimp}
            loading={fetching}
            title="MailChimp"
            bodyText="Automatically sync your patrons to your MailChimp account so you can put them in automated email workflows and segmented lists."
            backgroundImagePath={'mailchimp.png'}
            connectHandler={() => window.location.replace(`https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_MAILCHIMP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MAILCHIMP_REDIRECT_URI}`)}
            editPath="/settings/integrations/mailchimp"
          />
        </Box>
        <Box>
          <IntegrationCard
            connected={!!integrations.stripe}
            loading={fetching}
            title="Stripe"
            bodyText="Connect your stripe account to receive membership payouts to your account."
            backgroundImagePath={'connect_stripe.png'}
            connectHandler={() => window.location.replace(`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_STRIPE_REDIRECT_URI}`)}
          />
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    borderRadius: 5,
    border: '2px solid red'
  },
  cardContainer: {
    display: 'flex'
  }
})

export default Settings
