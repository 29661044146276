import { FunctionComponent, useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextField } from 'formik-material-ui'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import DetailOverlayPage from '../../components/DetailOverlay/Container'
import TopBar from '../../components/DetailOverlay/TopBar'
import useEmployeeStore from '../../stores/employee'
import { EmployeeParams } from '../../types'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const NewEmployee: FunctionComponent = () => {

  const [editing, setEditing] = useState<boolean>(false)
  const { createEmployee } = useEmployeeStore()
  const navigate = useNavigate()
  const styles = useStyles()

  const helpEmail = 'help@stitch.vip'
  const defaultErrorMessage = (
    <span>
      Failed to create employee. If this problem persists, please contact Stitch at &nbsp;
      <a href={`mailto:${helpEmail}`} className={styles.emailLink}>{helpEmail}</a>
    </span>
  )

  const handleSubmit = async (formData: EmployeeParams) => {
    formData.name = formData.name.trim();
    try {
      const [success, errorMessage] = await createEmployee(formData)
      if (!success) {
        toast.error(`Error: ${errorMessage}` || defaultErrorMessage)
        return
      }

      navigate('/employees');
      toast.success(`Added ${formData.name}`);
    } catch (error) {
      toast.error(defaultErrorMessage)
    }
  }

  return (
    <DetailOverlayPage>
      <TopBar breadCrumbs={[
        { title: 'Employees', linkPath: '/employees' },
        { title: 'Manage Employees', linkPath: '/employees' },
        { title: 'New Employee', linkPath: '' },
      ]} closePath={'/employees'}
      />
      <Box p={4} mb={4} className={styles.container}>
        <Box className={styles.formContainer}>
          <Box className={styles.titleContainer} mb={2}>
            <Typography variant="h3">New Employee</Typography>
          </Box>
          <Formik
            enableReinitialize
            initialValues={{
              name: '',
            }}
            onSubmit={handleSubmit}

            onReset={() => {
              setEditing(false)
            }}
            validationSchema={
              Yup.object({
                name: Yup.string().trim(),
              })
            }
          >

            <Form>
              <Box mb={2}>
                <Field
                  fullWidth
                  required
                  component={TextField}
                  name={'name'}
                  label="Name"
                  InputProps={{
                    style: {
                      fontWeight: !editing ? 'bold' : 'inherit'
                    }
                  }}
                />
                <Box mt={3} className={styles.buttonContainer}>
                  <Box mr={2} className={styles.cancelButton}>
                    <Button variant="text" color="secondary" type={'reset'} >Cancel</Button>
                  </Box>
                  <Button type="submit">Add Employee</Button>
                </Box>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </DetailOverlayPage>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    width: '80%',
    maxWidth: 400,
  },
  cancelButton: {
    display: 'inline'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stateZipContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  emailLink: {
    textDecoration: 'underline'
  }
})

export default NewEmployee