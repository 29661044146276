import { useEffect, FunctionComponent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { User, Award } from 'react-feather'
import Submenu from '../../components/Submenu'
import DetailOverlayPage from '../../components/DetailOverlay/Container'
import TopBar from '../../components/DetailOverlay/TopBar'
import useMembershipsStore from '../../stores/memberships'

const MembershipDetail: FunctionComponent = () => {
  const { getSimpleMembership, memberships } = useMembershipsStore()
  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()

  useEffect(() => {
    if (!id) {
      navigate('/memberships')
      return
    }

    getSimpleMembership(id)
  }, [id, getSimpleMembership, navigate])

  if (!id) {
    navigate('/memberships')
    return null
  }

  const membership = memberships[id]

  return (
    <DetailOverlayPage>
      <TopBar breadCrumbs={[
        { title: 'Memberships', linkPath: '/memberships' },
        { title: membership?.title, linkPath: '' },
      ]} closePath={'/memberships'}
      />
      <Submenu
        basePath={`/memberships/${id}`}
        items={[
          {
            id: 'subscriptions',
            icon: User,
            title: 'Subscriptions',
            subPath: ''
          },
          {
            id: 'benefits',
            icon: Award,
            title: 'Benefits',
            subPath: '/benefits'
          },
          // {
          //   id: 'pricePoints',
          //   icon: DollarSign,
          //   title: 'Price Points',
          //   subPath: '/price_points'
          // },
        ]}
      />
    </DetailOverlayPage>
  )
}

export default MembershipDetail
