import { FunctionComponent, useState } from 'react'
import { PatronBenefit } from '../../types'
import BenefitActions from './BenefitActions'
import { TableRow, TableCell, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import useBenefitStore from '../../stores/patronageBenefit'
import GreenCheck from '../../components/CircleIcons/Green'
import GreyDash from '../../components/CircleIcons/Grey'
import RedX from '../../components/CircleIcons/Red'
import colors from '../../colors'
import clsx from 'clsx'

type Props = {
  benefitId: string
  benefitCycleId: string,
}

const StatusPill: FunctionComponent<{ benefit: PatronBenefit }> = ({ benefit }) => {
  if (benefit.status === 'redeemed') return <GreenCheck hoverText={`Redeemed at ${benefit.redeemedAt}`} />
  if (benefit.status === 'available') return <GreyDash hoverText="Available" />
  if (benefit.status === 'expired') return <RedX hoverText="Expired" />
  if (benefit.status === 'unavailable') return <GreyDash hoverText="Not Yet Available" />
  return <></>
}

const BenefitRow: FunctionComponent<Props> = ({ benefitId, benefitCycleId }) => {
  const styles = useStyles()
  const benefit = useBenefitStore((store) => {
    return store.patronageBenefits[benefitId]
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  if (!benefit) return null
  const used = `${benefit.redemptions.length} / ${benefit.qty}`
  return (
    <TableRow className={clsx(isLoading && styles.loading)} key={benefit.uuid}>
      <TableCell>{benefit.name}</TableCell>
      <TableCell size="small">{isLoading ? <CircularProgress size={24} /> : benefit.startDate}</TableCell>
      <TableCell>{isLoading ? <CircularProgress size={24} /> : benefit.expirationDate}</TableCell>
      <TableCell size="small">{isLoading ? <CircularProgress size={24} /> : `${benefit.redemptions.length} / ${benefit.qty}`}</TableCell>
      <TableCell align="right"><BenefitActions handleLoading={setIsLoading} benefit={benefit} benefitCycleId={benefitCycleId} /></TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles({
  loading: {
    opacity: 0.5,
    background: colors.GREY_6
  }
})

export default BenefitRow
