import { FunctionComponent } from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { X } from 'react-feather'
import colors from '../../colors'

type Props = {
  size?: number,
  hoverText?: string,
}

const Green: FunctionComponent<Props> = ({ size = 24, hoverText = '' }) => {
  const styles = useStyles()
  return (
    <Tooltip title={hoverText}>
      <Box className={styles.container}style={{ width: size, height: size }}>
        <X size={size - 8} strokeWidth={3}/>
      </Box>
    </Tooltip>
  )
}

const useStyles = makeStyles({
  container: {
    background: colors.LIGHT_RED,
    color: colors.DARK_RED,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export default Green
