import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import { PatronBenefit, BenefitCycle } from '../types'
import axios from '../utils/axios'
import usePatronageBenefitStore from './patronageBenefit'
import useSubscriptionStore from './subscription'

type BenefitCyclesObject = Record<string, BenefitCycle>

type Store = {
  benefitCycles: BenefitCyclesObject,
  patronageBenefits: Record<string, PatronBenefit>,
  fetchBenefitCyclesForSubsription: (id: string) => Promise<void>,
  addBenefitCycles: (benefitCycles: BenefitCyclesObject) => void,
  removePatronageBenefit: (patronageBenefitId: string, benefitCycleId: string) => void,
  addPatronageBenefit: (patronageBenefitId: string, benefitCycleId: string) => void,
  rehydrated: boolean,
  setRehydrated: (isRehydrated: boolean) => void
}

const createFunction = (set: Function, get: Function): Store => ({
  benefitCycles: {},
  patronageBenefits: {},
  addBenefitCycles: (benefitCycles: BenefitCyclesObject) => {
    const patronageBenefits = Object.values(benefitCycles).map((bc) => bc.patronageBenefits || []).flat()
    usePatronageBenefitStore.getState().addPatronageBenefits(patronageBenefits)

    set((state: Store) => ({
      ...state,
      benefitCycles: {
        ...state.benefitCycles,
        ...benefitCycles,
      }
    }))
  },
  fetchBenefitCyclesForSubsription: async (id: string) => {
    const resp = await axios.get(`/subscriptions/${id}/benefit_cycles`)
    const subscription = resp.data.subscription
    const benefitCycles = subscription.benefitCycles

    useSubscriptionStore.setState((state) => ({
      ...state,
      subscriptions: {
        ...state.subscriptions,
        [subscription.id]: {
          ...subscription
        }
      }
    }))

    get().addBenefitCycles(benefitCycles)
  },
  removePatronageBenefit: (patronageBenefitId: string, benefitCycleId: string) => {
    let patronageBenefitIds = [...get().benefitCycles[benefitCycleId].patronageBenefitIds]
    // Remove the id from patronageBenefitIds
    patronageBenefitIds = patronageBenefitIds.filter((id: string) => id !== patronageBenefitId)

    set((store: Store) => ({
      ...store,
      benefitCycles: {
        ...store.benefitCycles,
        [benefitCycleId]: {
          ...store.benefitCycles[benefitCycleId],
          patronageBenefitIds
        }
      },
    }))
  },
  addPatronageBenefit: (patronageBenefitId: string, benefitCycleId: string) => {
    const patronageBenefitIds = [...get().benefitCycles[benefitCycleId]]
    // Remove the id from patronageBenefitIds
    patronageBenefitIds.push(patronageBenefitId)

    set((store: Store) => ({
      ...store,
      benefitCycles: {
        ...store.benefitCycles,
        [benefitCycleId]: {
          ...store.benefitCycles[benefitCycleId],
          patronageBenefitIds
        }
      },
    }))
  },
  rehydrated: false,
  setRehydrated: (isRehydrated: boolean) => set({ rehydrated: isRehydrated })
})

export const usePatronSubscriptionStore = create(devtools(persist(createFunction, {
  name: 'stitchBenefitCycles',
  onRehydrateStorage: (_: Store) => {
    return ((state?: Store | undefined, error?: unknown): void => {
      if (!state) {
        throw error
      }

      state.setRehydrated(true)
    })
  },
})))

export default usePatronSubscriptionStore
