import { MenuItem } from './types'
import {
  Home,
  Settings,
  Users,
  HelpCircle,
  Briefcase
} from 'react-feather'
import MembershipIcon from './components/MembershipIcon'

// We will often search through the values of this object, finding the first
// match. This means more specific routes should be at the top.
// For example, '/' should be at the bottom, because it will match all routes before it
export const MENU_ITEMS: Record<string, MenuItem> = {
  patrons: { title: 'Patrons', Icon: Users, path: '/patrons', id: 'patrons' },
  memberships: { title: 'Memberships', Icon: MembershipIcon, path: '/memberships', id: 'memberships' },
  settings: { title: 'Settings', Icon: Settings, path: '/settings', id: 'settings' },
  employees: { title: 'Employees', Icon: Briefcase, path: '/employees', id: 'employees' },
  help: { title: 'Help & Support', Icon: HelpCircle, path: '/help', id: 'help' },
  dash: { title: 'Dashboard', Icon: Home, path: '/', id: 'dash' },
}

export const SPACING = 8
