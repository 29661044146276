import { FunctionComponent } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { SPACING } from '../../constants'

const App: FunctionComponent = () => {
  const styles = useStyles()

  return (
      <Box className={styles.mainContainer}>
        <Box className={styles.bodyContainer}>
          SEARCH
        </Box>
      </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: '100vh',
    display: 'flex'
  },
  bodyContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  innerContainer: {
    padding: SPACING * 4,
    flex: 1,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    }
  }
}))

export default App;

