import React, { FunctionComponent } from 'react'
import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../colors'

interface PillProps {
  value: string,
  color: string,
  bold?: boolean,
}

type StyleProps = {
  background: string,
  text: string,
}

type ColorKey = Record<string, StyleProps>

const colorKey: ColorKey = {
  green: { background: colors.PALE_GREEN, text: colors.DARK_GREEN },
  red: { background: colors.PALE_RED, text: colors.DARK_RED },
  blue: { background: colors.PALE_BLUE, text: colors.STITCH_BLUE },
}

const Pill: FunctionComponent<PillProps> = ({ value, color }) => {

  const styles = useStyles(colorKey[color] || colorKey['blue'])
  return (
    <Box className={styles.container}>
      { value }
    </Box>
  )
}

const useStyles = makeStyles<Theme, StyleProps>({
  container: {
    padding: '4px 8px',
    background: ({ background }) => background,
    color: ({ text }) => text,
    border: 'none',
    borderRadius: 100,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

export default Pill
