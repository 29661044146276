import { FunctionComponent } from 'react'
import LiteSubscriptionDetail from './LiteSubscriptionDetail'
import Search from './Search'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  Route,
  Routes,
} from "react-router-dom";
import { SPACING } from '../../constants'

const App: FunctionComponent = () => {
  const styles = useStyles()

  return (
    <Box className={styles.mainContainer}>
      <Box className={styles.bodyContainer}>
        <Routes>
          <Route path="/lite/subscriptions/:id" element={<LiteSubscriptionDetail />} />
          <Route path="/" element={<Search />} />
        </Routes>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  bodyContainer: {
    border: '1px solid green',
    borderRadius: 12,
    width: '100vw',
    maxWidth: '400px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  innerContainer: {
    padding: SPACING * 4,
    flex: 1,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    }
  }
}))

export default App;

