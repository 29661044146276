import { FunctionComponent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useNavigate } from 'react-router-dom'
import useEmployeeStore from '../../stores/employee'
import Pill from '../../components/Pill'
import { Employee } from '../../types'
import Table from '../../components/Table'

const ManageEmployees: FunctionComponent = () => {
  const { employees, getEmployees } = useEmployeeStore()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    (async () => {
      await getEmployees()
      setLoading(false)
    })()
  }, [getEmployees, setLoading])

  const styles = useStyles()

  const navigate = useNavigate()

  const handleAddEmployeeClick = () => {
    navigate('/employees/new');
  }

  return (
    <Box p={2}>
      <Box mb={2} className={styles.headingContainer}>
        <Box>
          <Typography variant={'h3'}>
            Manage Employees
          </Typography>
          <Typography variant={'caption'}>
            Add, edit, and archive employees.
          </Typography>
        </Box>
        <Box mr={3} mt={1}>
          <Button onClick={handleAddEmployeeClick} className={styles.addButton}>
            Add Employee +
          </Button>
        </Box>
      </Box>
      <Box>
        <Table<Employee> title={'Employees'}
          rows={Object.values(employees)}
          loading={loading}
          filters={[{ title: 'Status', matchingFunction: (e) => e.archivedAt ? "Archived" : "Active" }
          ]}
          columns={[{
            label: 'Name',
            cellFill: (e: Employee) => e.name,
            searchable: true,
            searchFunction: (e) => String(e.name),
            sortable: true,
            csv: true,
            sortValueGetter: (e) => String(e.name),
          },
          {
            label: 'Status',
            cellFill: (e: Employee) => e.archivedAt ? <Pill value={'Archived'} color={'red'} /> : <Pill value={'Active'} color={'green'} />,
            searchable: false,
            hide: false,
            csv: true,
            sortValueGetter: (e: Employee) => e.archivedAt ? 'Archived' : 'Active',
            csvFill: (e: Employee) => e.archivedAt ? 'Archived' : 'Active',
          },
          ]}
          handleRowClicked={(e: Employee) => { navigate(`/employees/${e.id}`); }}
          id={'manage-employees'}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  addButton: {
    marginLeft: 'auto'
  },
})

export default ManageEmployees
