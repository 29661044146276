import { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/styles'
import colors from '../colors'
const Help: FunctionComponent = () => {
  const styles = useStyles()
  return (
    <p>
      If you're having an issue, <a href="mailto:help@stitch.vip" className={styles.link}>contact us directly</a>
    </p>
  )
}

const useStyles = makeStyles({
  link: {
    textDecoration: 'underline',
    color: colors.BRIGHT_BLUE
  }
})

export default Help
