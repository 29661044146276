import { FunctionComponent } from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ArrowLeft } from 'react-feather'
import colors from '../../colors'

type Props = {
  size?: number,
  hoverText?: string,
  Icon?: FunctionComponent,
}

const Blue: FunctionComponent<Props> = ({ size = 24, hoverText = '', Icon = ArrowLeft }) => {
  const styles = useStyles()
  return (
    <Tooltip title={hoverText}>
      <Box className={styles.container} style={{ width: size, height: size }}>
        <Icon size={size - 8} strokeWidth={3} />
      </Box>
    </Tooltip>
  )
}

const useStyles = makeStyles({
  container: {
    background: colors.PALE_BLUE,
    color: colors.STITCH_BLUE,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export default Blue
