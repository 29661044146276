import React, { FunctionComponent, useEffect, useCallback, } from 'react'
import TextField from '@material-ui/core/TextField';
import { fieldToTextField, TextFieldProps } from 'formik-material-ui';

// Helper function to insert some characters into a string at a given index
const insertStr = (originalString: string, fragmentToInsert: string, index: number):string => {
  return originalString.slice(0, index) + fragmentToInsert + originalString.slice(index)
}

const PhoneInput: FunctionComponent<TextFieldProps> = (props) => {
  const { form: { setFieldValue }, field: { name, value } } = props

  const formatNumber = useCallback((val: string, isBackspace: boolean): string => {
    let formattedVal = val

    // Remove country code if exists
    if (formattedVal.slice(0, 2) === "+1") {
      formattedVal = formattedVal.substring(2)
    }

    // Got to do some trickery when backspaces are used
    if (isBackspace && formattedVal.length === "(208) 123".length) {
      // When the length is "(208) 123", it means we just deleted the "-"
      // take an extra character

      formattedVal = formattedVal.slice(0, -1)
    } else if (isBackspace && formattedVal.length === "(208)".length) {
      // We just removed the space, remove the paren and the next number too
      formattedVal = formattedVal.slice(0, -2)
    }

    formattedVal = formattedVal.replace(/[^0-9]/g, '')

    if (formattedVal.length === 0) { return '' }

    // Insert the parens if we have at least one number
    formattedVal = insertStr(formattedVal, '(', 0)
    // (2089

    // Exit early if we're not ready for the next format
    if (formattedVal.length <= "(20".length) { return formattedVal }

    formattedVal = insertStr(formattedVal, ') ', 4)

    // Exit early if we're not ready for the next format
    if (formattedVal.length <= '(208) 94'.length) { return formattedVal }

    formattedVal = insertStr(formattedVal, '-', 9)

    // Limit the length to a formatted 10 digit number
    formattedVal = formattedVal.slice(0, 14)

    return formattedVal
  }, [])

  useEffect(() => {
    const val = formatNumber(String(value), false)
    setFieldValue(name, val)
  }, [name, value, formatNumber, setFieldValue])

  const handleChange = (e: any) => {
    const isBackspace = e.nativeEvent.inputType === "deleteContentBackward"
    const formattedVal = formatNumber(e.target.value, isBackspace)

    setFieldValue(name, formattedVal)
  }

  return (
    <TextField
      {...fieldToTextField(props)}
      inputProps={{ type: 'tel' }}
      value={formatNumber(props.field.value, false)}
      onChange={handleChange}
    />
  )
}

export default PhoneInput
