import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Outlet, useNavigate, } from "react-router-dom";
import { defaultTheme } from './themes'
import { useAuthStore } from './stores/auth'
import { ToastContainer } from 'react-toastify';
import { useEffect, ReactNode } from 'react';

export default function App() {
  return (
    <>
      <HelmetProvider>
        <ThemeProvider theme={defaultTheme}>
          <Helmet>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1"
            />
            <title>Stitch</title>
            <meta charSet="utf-8" />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap"
            />
          </Helmet>
          <CssBaseline />
          <ToastContainer />
          <Outlet />
        </ThemeProvider>
      </HelmetProvider>
    </>
  )
}
interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const jwt = useAuthStore((state) => state.jwt);
  const rehydrated = useAuthStore((state) => state.rehydrated);
  const navigate = useNavigate();

  useEffect(() => {
    if (rehydrated && !jwt) {
      navigate('/login');
    }
  }, [rehydrated, jwt, navigate]);

  if (!rehydrated) return null;

  return <>{children}</>;
};