import { FunctionComponent } from 'react'

interface Props {
  color?: string;
  size?: string;
}

const MembershipIcon: FunctionComponent<Props> = ({ color = 'currentColor', size = 24, ...props }) => {
  return (
    <div style={{display: 'flex', alignItems: 'center'}} {...props }>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="4" width="22" height="15" rx="2" stroke={color} strokeWidth="2"/>
        <line x1="13.5" y1="8.5" x2="19.5" y2="8.5" stroke={color} strokeLinecap="round"/>
        <line x1="13.5" y1="11.5" x2="19.5" y2="11.5" stroke={color} strokeLinecap="round"/>
        <line x1="13.5" y1="14.5" x2="19.5" y2="14.5" stroke={color} strokeLinecap="round"/>
        <path d="M7.26224 7.73176C7.33707 7.50144 7.66293 7.50144 7.73776 7.73176L8.45418 9.93668C8.48765 10.0397 8.58364 10.1094 8.69195 10.1094H11.0103C11.2525 10.1094 11.3532 10.4193 11.1573 10.5617L9.28167 11.9244C9.19405 11.9881 9.15738 12.1009 9.19085 12.2039L9.90727 14.4088C9.98211 14.6391 9.71849 14.8307 9.52256 14.6883L7.64695 13.3256C7.55932 13.2619 7.44068 13.2619 7.35305 13.3256L5.47744 14.6883C5.28151 14.8307 5.01789 14.6391 5.09273 14.4088L5.80915 12.2039C5.84262 12.1009 5.80595 11.9881 5.71833 11.9244L3.84272 10.5617C3.64679 10.4193 3.74749 10.1094 3.98967 10.1094H6.30805C6.41636 10.1094 6.51235 10.0397 6.54582 9.93668L7.26224 7.73176Z" fill={color}/>
    </svg>
  </div>
  )
}

export default MembershipIcon
