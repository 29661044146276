import { FunctionComponent, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Edit, Save, Trash, UserPlus } from 'react-feather'
import { Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextField } from 'formik-material-ui'
import useEmployeeStore from '../../stores/employee'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import DetailOverlayPage from '../../components/DetailOverlay/Container'
import TopBar from '../../components/DetailOverlay/TopBar'
import { Employee } from '../../types'
import Pill from '../../components/Pill'
import { format } from 'date-fns';
import { toast } from 'react-toastify'


const EmployeeDetail: FunctionComponent = () => {

  const { getEmployee, updateEmployee, toggleArchiveEmployee } = useEmployeeStore()
  const { id } = useParams<{ id: string }>()
  const employee = useEmployeeStore((state) => id && state.employees[id])

  const [editing, setEditing] = useState<boolean>(false)

  const styles = useStyles()

  const navigate = useNavigate()

  type ButtonParams = { employee: Employee }
  const ArchiveButton = ({ employee }: ButtonParams) => {
    if (!employee.archivedAt) {
      return (
        <Box ml={2} className={styles.cancelButton}>
          <Button
            endIcon={<Trash size={20} />}
            variant="text" color="secondary"
            onClick={async () => {
              try {
                await toggleArchiveEmployee(employee.id, !employee.archivedAt);
                navigate('/employees')
                toast.success(`Archived ${employee.name}`)
              } catch (error) {
                toast.error('Failed to archive')
              }
            }}
          >
            Archive
          </Button>
        </Box>
      )
    } else {
      return (

        <Box ml={2} className={styles.cancelButton}>
          <Button
            endIcon={<UserPlus size={20} />}
            variant="text"
            color="success"
            onClick={async () => {
              try {
                await toggleArchiveEmployee(employee.id, !employee.archivedAt);
                navigate('/employees')
                toast.success(`Unarchived ${employee.name}`)
              } catch (error) {
                toast.error('Failed to unarchive employee')
              }
            }}
          >
            Unarchive
          </Button>
        </Box>
      )
    }
  }

  useEffect(() => {
    if (!id) return
    getEmployee(id)
  }, [id, getEmployee])

  const helpEmail = 'help@stitch.vip'
  const defaultErrorMessage = (
    <span>
      Failed to create employee. If this problem persists, please contact Stitch at &nbsp;
      <a href={`mailto:${helpEmail}`} className={styles.emailLink}>{helpEmail}</a>
    </span>
  )

  const handleSubmit = async (formData: Employee) => {
    formData.name = formData.name.trim();
    try {
      const [success, errorMessage] = await updateEmployee(formData)
      if (!success) {
        toast.error(`Error: ${errorMessage}` || defaultErrorMessage)
        return
      }

      navigate('/employees');
      toast.success('Employee updated');
    } catch (error) {
      toast.error(defaultErrorMessage)
    }
  }

  if (!employee) return null
  return (
    <DetailOverlayPage>
      <TopBar breadCrumbs={[
        { title: 'Employees', linkPath: '/employees' },
        { title: 'Manage Employees', linkPath: '/employees' },
        { title: employee ? employee.name : '', linkPath: '' },
      ]} closePath={'/employees'}
      />
      <Box p={4} mb={4} className={styles.container}>
        <Box className={styles.formContainer}>
          <Box className={styles.titleContainer} mb={2}>
            <Typography variant="h3">{employee.name}</Typography>
          </Box>
          <Box mt={3} mb={2} className={styles.buttonContainer}>
            {editing ? null : (
              employee.archivedAt ? (
                <Pill color='red' value={`Archived on ${format(new Date(employee.archivedAt), 'MM/dd/yyyy')}`} />

              ) : (
                <Button endIcon={<Edit size={20} />} onClick={() => setEditing(true)}>
                  Edit
                </Button>
              )
            )
            }
            {editing ? null : <ArchiveButton employee={employee} />}
          </Box>

          <Formik
            enableReinitialize
            initialValues={{
              id: employee?.id || '',
              name: employee?.name || '',
              createdAt: employee?.createdAt || '',
            }}
            onSubmit={handleSubmit}
            onReset={() => {
              setEditing(false)
            }}
            validationSchema={
              Yup.object({
                name: Yup.string(),
              })
            }
          >
            <Form>
              <Box mb={2}>
                <Field
                  fullWidth
                  required
                  component={TextField}
                  name={'name'}
                  label="Name"
                  InputProps={{
                    style: {
                      fontWeight: !editing ? 'bold' : 'inherit'
                    }
                  }}
                  disabled={!editing}
                />
              </Box>
              {editing ? (
                <Box className={styles.buttonContainer}>
                  <Box mr={2} className={styles.cancelButton}>
                    <Button variant="text" color="secondary" type={'reset'} >Cancel</Button>
                  </Box>
                  <Button type="submit" endIcon={<Save size={20} />}>Save</Button>
                </Box>
              ) : null}
            </Form>
          </Formik>
        </Box>
      </Box>
    </DetailOverlayPage>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    width: '80%',
    maxWidth: 400,
  },
  cancelButton: {
    display: 'inline'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleContainer: {
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stateZipContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  emailLink: {
    textDecoration: 'underline'
  }
})

export default EmployeeDetail