import { FunctionComponent } from 'react'
import { CircularProgress, Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextField } from 'formik-material-ui'
import { Formik, Field, Form } from 'formik'
import { Link } from 'react-router-dom'
import axios from '../../utils/axios'
import * as Yup from 'yup'

type Props = {
  onSuccess: (email: string) => void,
  onError: (message: string) => void
}

const ProfileForm: FunctionComponent<Props> = ({ onSuccess, onError }) => {
  const styles = useStyles()
  return (
    <Box p={4} className={styles.container}>
      <Box className={styles.formContainer}>
        <Box className={styles.titleContainer} mb={2}>
          <Typography variant="h3">Reset Password</Typography>
        </Box>
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
          }}
          onSubmit={async (values) => {
            const resp = await axios.post('/backend_user/start_password_reset', {
              email: values.email
            })
            if (resp.status === 200) {
              onSuccess(values.email)
            } else {
              onError(`Stitch returned an error when processing your request. Status code: ${resp.status}`)
            }
          }}
          validationSchema={
            Yup.object({
              email: Yup.string().required().email(),
            })
          }
        >
          { (formikProps) => {
              return (
                <Form>
                  <Box mb={2}>
                    <Field
                      fullWidth
                      required
                      component={TextField}
                      name={'email'}
                      type={'email'}
                      label="Email"
                    />
                  </Box>
                  <Box className={styles.buttonContainer}>
                    <Box mr={2} className={styles.cancelButton}>
                      <Link to="/login">
                        <Button variant="text" color="secondary">Cancel</Button>
                      </Link>
                    </Box>
                    <Button type="submit" disabled={(!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty)}>
                      { formikProps.isSubmitting ? <CircularProgress size={16} style={{marginRight: 8}}/> : null }
                      Send Password Reset
                    </Button>
                  </Box>
                </Form>
              )
            }
          }
        </Formik>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },
  formContainer: {
    width: '90%',
    maxWidth: 400,
  },
  cancelButton: {
    display: 'inline'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  disabled: {
    fontWeight: 'bold'
  }
})

export default ProfileForm
