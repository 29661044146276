const colors = {
  STITCH_BLUE: '#113355',
  LIGHT_BLUE: '#bbddff',
  PALE_BLUE: '#f1f8ff',
  BRIGHT_BLUE: '#3385ff',
  STITCH_BLACK: '#030332',
  GREY_1: '#444455',
  GREY_2: '#666677',
  GREY_3: '#888899',
  GREY_4: '#bbbbcc',
  GREY_5: '#e0e0e0',
  GREY_6: '#f2f2f2',
  GREY_7: '#fafafb',
  WHITE: '#ffffff',
  RED: '#ee4455',
  LIGHT_RED: '#f89da6',
  PALE_RED: '#ffebed',
  DARK_RED: '#bc3643',
  GREEN: '#219653',
  LIGHT_GREEN: '#83cba2',
  PALE_GREEN: '#effaf4',
  DARK_GREEN: '#1c653b',
  PURPLE: '#8f67ff',
  LIGHT_PURPLE: '#d6c8ff',
  PALE_PURPLE: '#f6f2ff',
  DARK_PURPLE: '#523999',
  YELLOW: '#ffcf4a',
  LIGHT_YELLOW: '#ffee99',
  PALE_YELLOW: '#fffbea',
  DARK_YELLOW: '#ffcf4a',
  ORANGE: '#ff9a3e',
  LIGHT_ORANGE: '#ffdbc0',
  PALE_ORANGE: '#fff8f2',
  DARK_ORANGE: '#e46b00',
}

export default colors
