import { FunctionComponent, useState } from "react";
import { Box, Button, TextField, Typography } from '@material-ui/core'
import DetailOverlayPage from '../../components/DetailOverlay/Container'
import TopBar from '../../components/DetailOverlay/TopBar'
import { makeStyles } from '@material-ui/styles'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import useMembershipsStore from "../../stores/memberships";
import { useNavigate } from "react-router-dom";
import { MembershipParams } from "../../types";
import { toast } from "react-toastify";

const NewMembership: FunctionComponent = () => {

  const [editing, setEditing] = useState<boolean>(false)
  const { createMembership } = useMembershipsStore()
  const navigate = useNavigate()
  const styles = useStyles()

  const helpEmail = 'help@stitch.vip'
  const defaultErrorMessage = (
    <span>
      Failed to create membership. If this problem persists, please contact Stitch at &nbsp;
      <a href={`mailto:${helpEmail}`} className={styles.emailLink}>{helpEmail}</a>
    </span>
  )

  const handleSubmit = async (formData: MembershipParams) => {
    formData.title = formData.title.trim();
    try {
      const membershipOrError = await createMembership(formData)
      if (typeof membershipOrError === 'string') {
        toast.error(`Error: ${membershipOrError}` || defaultErrorMessage)
        return
      }

      const membership = membershipOrError

      navigate(`/memberhsips/${membership.uuid}`);
      toast.success(`Added ${formData.title}`);
    } catch (error) {
      toast.error(defaultErrorMessage)
    }
  }

  return (
    <DetailOverlayPage>
      <TopBar breadCrumbs={[
        { title: 'Memberships', linkPath: '/memberships' },
        { title: 'New Membership', linkPath: '' },
      ]} closePath={'/memberships'}
      />
      <Box p={4} mb={4} className={styles.container}>
        <Box className={styles.formContainer}>
          <Box className={styles.titleContainer} mb={2}>
            <Typography variant="h3">New Membership</Typography>
          </Box>
          <Formik
            enableReinitialize
            initialValues={{
              title: '',
            }}
            onSubmit={handleSubmit}

            onReset={() => {
              setEditing(false)
            }}
            validationSchema={
              Yup.object({
                name: Yup.string().trim(),
              })
            }
          >

            <Form>
              <Box mb={2}>
                <Field
                  fullWidth
                  required
                  component={TextField}
                  name={'name'}
                  label="Title"
                  InputProps={{
                    style: {
                      fontWeight: !editing ? 'bold' : 'inherit'
                    }
                  }}
                />
                <Box mt={3} className={styles.buttonContainer}>
                  <Box mr={2} className={styles.cancelButton}>
                    <Button variant="text" color="secondary" type={'reset'} >Cancel</Button>
                  </Box>
                  <Button type="submit">Add Membership</Button>
                </Box>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </DetailOverlayPage>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    width: '80%',
    maxWidth: 400,
  },
  cancelButton: {
    display: 'inline'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stateZipContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  emailLink: {
    textDecoration: 'underline'
  }
})

export default NewMembership