import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../colors'
import { Link } from 'react-router-dom'
import LoginForm from '../components/forms/LoginForm'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from '../stores/auth'

export default function Login() {
  const styles = useStyles()
  const jwt = useAuthStore((state) => state.jwt)
  const navigate = useNavigate()

  if (jwt) {
    navigate('/')
  }

  return (
    <>
      <Box p={2} className={styles.header}>
        <Link to="/">
          <img
            src={"/images/bare_logo.png"}
            alt="Stitch Logo"
            width={72}
            height={48}
          />
        </Link>
      </Box>
      <Box pt={4} className={styles.container}>
        <LoginForm />
      </Box>
    </>
  )
}

const useStyles = makeStyles({
  header: {
    background: colors.STITCH_BLUE,
    color: colors.WHITE,
    display: 'flex',
    alignItems: 'center',
    height: '10vh',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    width: '100vw',
    background: colors.WHITE,
    color: colors.STITCH_BLACK,
    height: '90vh',
  }
})