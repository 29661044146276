import { FunctionComponent, useState, useEffect } from 'react'
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../../colors'
import BenefitRow from './BenefitRow'
import clsx from 'clsx'
import BlueCircle from '../../components/CircleIcons/Blue'
import { ArrowRight, ArrowLeft } from 'react-feather'
import useBenefitCycleStore from '../../stores/benefitCycle'
import { CircularProgress } from '@material-ui/core'
import useSubscriptionStore from '../../stores/subscription'

type Props = {
  subscriptionId: string
}

const MembershipBenefits: FunctionComponent<Props> = ({ subscriptionId }) => {
  const fetchBenefitCyclesForSubsription = useBenefitCycleStore((store) => store.fetchBenefitCyclesForSubsription)
  const subscription = useSubscriptionStore((state) => {
    return state.subscriptions[subscriptionId]
  })
  const [loading, setLoading] = useState(true)
  const [benefitCycleId, setBenefitCycleId] = useState('')
  const benefitCycle = useBenefitCycleStore((store) => {
    if (!benefitCycleId) return null
    return store.benefitCycles[benefitCycleId]
  })

  useEffect(() => {
    (async () => {
      await fetchBenefitCyclesForSubsription(subscriptionId)

      setLoading(false)
    })()
  }, [subscriptionId, fetchBenefitCyclesForSubsription])

  useEffect(() => {
    if (!subscription) return
    setBenefitCycleId(subscription.currentBenefitCycleId)
  }, [subscription])

  const handleIssueBenefitClick = () => {
    alert('issue benefit')
  }


  const styles = useStyles()

  const handleCycleChange = (goToCycleId?: string) => {
    if (!goToCycleId) return
    setBenefitCycleId(goToCycleId)
  }

  const goToPreviousCycle = () => {
    if (!benefitCycle) return
    handleCycleChange(benefitCycle.previousBenefitCycleId)
  }

  const goToNextCycle = () => {
    if (!benefitCycle) return
    handleCycleChange(benefitCycle.nextBenefitCycleId)
  }

  if (loading) {
    return <CircularProgress />
  }

  if (!subscription || !benefitCycle) {
    return <p>{benefitCycleId}</p>
  }
  console.log('benefitCycle', benefitCycle)
  return (
    <Box p={4} className={styles.container}>
      <Box mb={2} className={styles.membershipTitleContainer}>
        <Box mb={2}>
          <Typography><strong>{subscription.membership?.title}</strong></Typography>
        </Box>
        <Box className={styles.dateBox}>
          <Box mb={1}>
            <Typography>{benefitCycle.startDate} to {benefitCycle.endDate}</Typography>
          </Box>
          <Box className={styles.arrowsContainer}>
            <Box onClick={goToPreviousCycle} className={clsx(styles.arrow, !benefitCycle.previousBenefitCycleId && styles.disabledArrow)}>
              <BlueCircle size={28} Icon={ArrowLeft} />
            </Box>
            <Box onClick={goToNextCycle} className={clsx(styles.arrow, !benefitCycle.nextBenefitCycleId && styles.disabledArrow)}>
              <BlueCircle size={28} Icon={ArrowRight} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box pl={2}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell size="small">Start Date</TableCell>
                <TableCell>Expiration Date</TableCell>
                <TableCell size="small">Qty Used</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableContainer}>
              {benefitCycle.patronageBenefitIds.map((id: string) => {
                return (
                  <BenefitRow key={id} benefitId={id} benefitCycleId={benefitCycle.id} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          false ?
            <Box mt={2}>
              <Button onClick={handleIssueBenefitClick} variant={'text'} style={{ background: colors.LIGHT_BLUE }} className={styles.issueButton}>
                Issue Benefit
              </Button>
            </Box> : null
        }
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  tableContainer: {

  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    width: '80%',
    maxWidth: 400,
  },
  issueButton: {
    background: colors.PALE_BLUE,
  },
  membershipTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  cancelButton: {
    display: 'inline'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  disabled: {
    fontWeight: 'bold'
  },
  dateBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  arrow: {
    cursor: 'pointer',
    marginRight: 8,
  },
  disabledArrow: {
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  arrowsContainer: {
    display: 'flex',
    flexDirection: 'row',
  }
})

export default MembershipBenefits
