import { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../../colors'
import { Link, useNavigate } from 'react-router-dom'
import PasswordResetRequestForm from './RequestForm'
import { useAuthStore } from '../../stores/auth'

export default function RequestPasswordReset() {
  const styles = useStyles()
  const jwt = useAuthStore((state) => state.jwt)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  if (jwt) {
    navigate('/')
  }

  const handleSuccess = (email: string) => {
    setSuccess(true)
    setEmail(email)
  }

  const handleError = (error: string) => {
    alert(error)
  }

  const SuccessMessage = () => {
    return (
      <Box p={4}>
        <Box mb={2}>
          <Typography variant="h3">Check Your Email</Typography>
        </Box>
        <Box mb={2}>
          <Typography>A password reset email was sent to <strong>{email}</strong></Typography>
        </Box>
        <Box className={styles.link} onClick={() => { setSuccess(false) }}>
          Enter a different email
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box p={2} className={styles.header}>
        <Link to="/">
          <img
            src={"/images/bare_logo.png"}
            alt="Stitch Logo"
            width={72}
            height={48}
          />
        </Link>
      </Box>
      <Box pt={4} className={styles.container}>
        {success
          ? <SuccessMessage />
          : <PasswordResetRequestForm onSuccess={handleSuccess} onError={handleError} />
        }
      </Box>
    </>
  )
}

const useStyles = makeStyles({
  header: {
    background: colors.STITCH_BLUE,
    color: colors.WHITE,
    display: 'flex',
    alignItems: 'center',
    height: '10vh',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    width: '100vw',
    background: colors.WHITE,
    color: colors.STITCH_BLACK,
    height: '90vh',
  },
  link: {
    color: colors.BRIGHT_BLUE,
    cursor: 'pointer'
  }
})