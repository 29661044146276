import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import stitchAxios from '../utils/axios'
import { Employee, EmployeeParams, EmployeeSortState, EmployeeSortableId } from '../types'
import axios from 'axios'

type EmployeesObject = Record<string, Employee>

type Store = {
  employees: EmployeesObject,
  toggleArchiveEmployee: (id: string, archive: boolean) => void,
  createEmployee: (employee: EmployeeParams) => Promise<[boolean, string]>,
  getEmployees: () => void,
  getEmployee: (id: string) => void,
  updateEmployee: (employee: Employee) => Promise<[boolean, string]>,
  rehydrated: boolean,
  setRehydrated: (isRehydrated: boolean) => void,
  searchTerm: string,
  sortState: EmployeeSortState,
  indexPageNumber: number,
  indexRowsPerPage: number,
}

const createFunction = (set: Function, get: Function): Store => ({
  employees: {},
  createEmployee: async (employee: EmployeeParams): Promise<[boolean, string]> => {
    try {
      const resp = await stitchAxios.post(`/employees`, {
        ...employee
      })

      set((state: Store) => ({ ...state, employees: { ...state.employees, [resp.data.id]: resp.data }, }))
      return [true, '']
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return [false, ((e.response && e.response.data.message) || 'Could not create employee.')]
      }
      throw e
    }
  },
  getEmployees: async () => {
    const response = await stitchAxios.get('/employees')
    const employeesArray = response.data

    const employees = {} as EmployeesObject

    employeesArray.forEach((employee: Employee) => {
      employees[employee.id] = employee
    })
    set((state: Store) => ({ ...state, employees }))
  },
  getEmployee: async (employeeId: string): Promise<void> => {
    const resp = await stitchAxios.get(`/employees/${employeeId}`)
    set((state: Store) => ({ ...state, employees: { ...state.employees, [employeeId]: resp.data }, }))
  },
  updateEmployee: async (employee: Employee): Promise<[boolean, string]> => {
    try {
      const resp = await stitchAxios.patch(`/employees/${employee.id}`, {
        ...employee
      })

      set((state: Store) => ({ ...state, employees: { ...state.employees, [employee.id]: resp.data }, }))
      return [true, '']
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return [false, ((e.response && e.response.data.message) || 'Could not update employee.')]
      }
      throw e
    }
  },
  toggleArchiveEmployee: async (id: string, archive: boolean): Promise<void> => {
    const employee = get().employees[id]
    if (!employee) {
      throw new Error(`Employee ID not found ${id}`)
    }

    const archivedAt = archive ? new Date() : null

    await get().updateEmployee({ ...employee, archivedAt })
  },
  rehydrated: false,
  setRehydrated: (isRehydrated: boolean) => set({ rehydrated: isRehydrated }),
  sortState: {
    active: true,
    ascending: true,
    key: EmployeeSortableId.name,
  },
  indexPageNumber: 0,
  indexRowsPerPage: 10,
  searchTerm: ''
})

export const useEmployeeStore = create(devtools(persist(createFunction, {
  name: 'stitchEmployees',
  partialize: (state: Store) => ({ employees: state.employees }),
  onRehydrateStorage: (_: Store) => {
    return ((state?: Store | undefined, error?: unknown): void => {
      if (!state) {
        throw error
      }

      state.setRehydrated(true)
    })
  },
})))

export default useEmployeeStore