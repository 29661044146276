import { FunctionComponent, useState } from 'react'
import { Edit, Save } from 'react-feather'
import { MenuItem, FormControl, InputLabel, Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { STATE_ABBREVIATIONS } from '../../utils/States'
import PhoneInput from '../../forms/PhoneInput'
import { TextField } from 'formik-material-ui'
import usePatronStore from '../../stores/patron'
import { Formik, Field, Form } from 'formik'
import { Patron } from '../../types'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'

type Props = {
  patronId?: string
}
const ProfileForm: FunctionComponent<Props> = ({ patronId }) => {

  const { updatePatron } = usePatronStore()
  const [editing, setEditing] = useState<boolean>(false)
  const idFromUrl = useParams().id
  const id = (patronId || idFromUrl)
  const patron = usePatronStore((store) => id && store.patrons[id])

  const styles = useStyles()

  if (!patron) {
    return null
  }

  return (
    <Box p={4} mb={4} className={styles.container}>
      <Box className={styles.formContainer}>
        <Box className={styles.titleContainer} mb={2}>
          <Typography variant="h3">Patron Profile</Typography>
          <Box>
            {editing ? null : <Button endIcon={<Edit size={20} />} onClick={() => setEditing(true)}>Edit</Button>}
          </Box>
        </Box>
        <Formik
          enableReinitialize
          initialValues={{
            id: patron?.id || '',
            email: patron?.email || '',
            firstName: patron?.firstName || '',
            lastName: patron?.lastName || '',
            addressLineOne: patron?.addressLineOne || '',
            addressLineTwo: patron?.addressLineTwo || '',
            city: patron?.city || '',
            state: patron?.state || '',
            zip: patron?.zip || '',
            shirtSize: patron?.shirtSize || '',
            phone: patron?.phone || '',
          }}
          onSubmit={async (values: Patron) => {
            setEditing(false)
            updatePatron(values)
          }}
          onReset={() => {
            setEditing(false)
          }}
          validationSchema={
            Yup.object({
              email: Yup.string().required().email(),
              firstName: Yup.string(),
              lastName: Yup.string(),
              phone: Yup.string(),
            })
          }
        >
          <Form>
            <Box mb={2}>
              <Field
                fullWidth
                required
                component={TextField}
                name={'email'}
                type={'email'}
                label="Email"
                InputProps={{
                  style: {
                    fontWeight: !editing ? 'bold' : 'inherit'
                  }
                }}
                disabled={!editing}
              />
              <Field
                fullWidth
                component={TextField}
                name={'firstName'}
                label="First Name"
                InputProps={{
                  style: {
                    fontWeight: !editing ? 'bold' : 'inherit'
                  }
                }}
                disabled={!editing}
              />
              <Field
                fullWidth
                component={TextField}
                name={'lastName'}
                label="Last Name"
                InputProps={{
                  style: {
                    fontWeight: !editing ? 'bold' : 'inherit'
                  }
                }}
                disabled={!editing}
              />
              <Field
                fullWidth
                component={PhoneInput}
                name={'phone'}
                label="Phone Number"
                InputProps={{
                  style: {
                    fontWeight: !editing ? 'bold' : 'inherit'
                  }
                }}
                disabled={!editing}
              />
              <FormControl>
                <InputLabel required>Unisex Shirt Size</InputLabel>
                <Field
                  component={TextField}
                  label={'Unisex Shirt Size'}
                  type='text'
                  select={true}
                  disabled={!editing}
                  InputProps={{
                    style: {
                      width: 200,
                      fontWeight: !editing ? 'bold' : 'inherit'
                    },
                  }}
                  name={'shirtSize'}
                >
                  {['xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl'].map((abv) => {
                    return (
                      <MenuItem key={abv} value={abv}>{abv.toUpperCase()}</MenuItem>
                    )
                  })}
                </Field>
              </FormControl>
              <Box mt={2}>
                <Typography variant={'h4'}>
                  Address
                </Typography>
                <Field
                  fullWidth
                  component={TextField}
                  name={'addressLineOne'}
                  label="Address"
                  InputProps={{
                    style: {
                      fontWeight: !editing ? 'bold' : 'inherit'
                    }
                  }}
                  disabled={!editing}
                />
                <Field
                  fullWidth
                  component={TextField}
                  name={'addressLineTwo'}
                  label="Apt, Suite, Unit Number, etc."
                  InputProps={{
                    style: {
                      fontWeight: !editing ? 'bold' : 'inherit'
                    }
                  }}
                  disabled={!editing}
                />
                <Field
                  fullWidth
                  component={TextField}
                  name={'city'}
                  label="City"
                  InputProps={{
                    style: {
                      fontWeight: !editing ? 'bold' : 'inherit'
                    }
                  }}
                  disabled={!editing}
                />
                <Box className={styles.stateZipContainer}>
                  <Box mr={1}>
                    <FormControl>
                      <InputLabel required>State</InputLabel>
                      <Field
                        component={TextField}
                        label={'State'}
                        type='text'
                        select={true}
                        disabled={!editing}
                        InputProps={{
                          style: {
                            width: 100,
                            fontWeight: !editing ? 'bold' : 'inherit'
                          },
                        }}
                        name={'state'}
                      >
                        {STATE_ABBREVIATIONS.map((abv) => {
                          return (
                            <MenuItem key={abv} value={abv}>{abv}</MenuItem>
                          )
                        })}
                      </Field>
                    </FormControl>
                  </Box>
                  <Field
                    label={'Zip'}
                    name={'zip'}
                    disabled={!editing}
                    component={TextField}
                    InputProps={{
                      style: {
                        width: 100,
                        fontWeight: !editing ? 'bold' : 'inherit'
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {editing ? (
              <Box className={styles.buttonContainer}>
                <Box mr={2} className={styles.cancelButton}>
                  <Button variant="text" color="secondary" type={'reset'} >Cancel</Button>
                </Box>
                <Button type="submit" endIcon={<Save size={20} />}>Save</Button>
              </Box>
            ) : null}
          </Form>
        </Formik>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    width: '80%',
    maxWidth: 400,
  },
  cancelButton: {
    display: 'inline'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    height: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stateZipContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  }
})

export default ProfileForm
