import { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../../colors'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import useAuthStore from '../../stores/auth'
import queryString from 'query-string'
import ResetForm from './ResetForm'
import { toast } from 'react-toastify'

export default function PasswordReset() {
  const [checkingToken, setCheckingToken] = useState<boolean>(true)
  const [tokenIsValid, setTokenIsValid] = useState<boolean>(false)
  const { checkPasswordResetToken } = useAuthStore()
  const styles = useStyles()
  const jwt = useAuthStore((state) => state.jwt)
  const location = useLocation()
  const { token, uuid } = queryString.parse(location.search)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      const tokenIsValid = await checkPasswordResetToken(String(token), String(uuid))
      setTokenIsValid(tokenIsValid)
      setCheckingToken(false)
    })()
  }, [location, checkPasswordResetToken, uuid, token])

  // Redirect if already signed in
  if (jwt) {
    navigate('/')
  }

  const handleSuccess = () => {
    navigate('/login')
    toast.success('Password reset! Please log in.')
  }

  const handleError = (message: string) => {
    toast.warning(message)
  }

  const InvalidToken = () => {
    return (
      <Box p={4}>
        <Box mb={2}>
          <Typography variant={'h3'}>
            Oops! Error.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            Your password reset link has already been used, or is expired!
          </Typography>
        </Box>
        <Box>
          <Link className={styles.link} to={'/password_reset/request'}>
            Request another reset link
          </Link>
        </Box>
      </Box>
    )
  }

  const Form = () => {
    if (token && tokenIsValid && !checkingToken) {
      return <ResetForm onSuccess={handleSuccess} onError={handleError} token={String(token)} uuid={String(uuid)} />
    }

    return (
      <InvalidToken />
    )
  }

  return (
    <>
      <Box p={2} className={styles.header}>
        <Link to="/">
          <img
            src={"/images/bare_logo.png"}
            alt="Stitch Logo"
            width={72}
            height={48}
          />
        </Link>
      </Box>
      <Box pt={4} className={styles.container}>
        {checkingToken
          ? <CircularProgress />
          : <Form />
        }
      </Box>
    </>
  )
}

const useStyles = makeStyles({
  header: {
    background: colors.STITCH_BLUE,
    color: colors.WHITE,
    display: 'flex',
    alignItems: 'center',
    height: '10vh',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    width: '100vw',
    background: colors.WHITE,
    color: colors.STITCH_BLACK,
    height: '90vh',
  },
  link: {
    color: colors.BRIGHT_BLUE
  }
})