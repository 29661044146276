import React from 'react'
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Pill from '../../components/Pill'
import SearchBar from '../../components/SearchBar'
import colors from '../../colors'
import Fuse from 'fuse.js'
import { useNavigate } from 'react-router-dom'
import useMembershipsStore from '../../stores/memberships'
import { Membership } from '../../types'

export default function Page() {
  const { memberships, getMemberships } = useMembershipsStore()
  const navigate = useNavigate()
  const [searchVal, setSearchVal] = React.useState('')
  const [loading, setLoading] = React.useState<boolean>(true)
  const [searchedMemberships, setSearchedMemberships] = React.useState(Object.values(memberships))
  const fuse = React.useMemo(() => new Fuse<Membership>(Object.values(memberships), {
    keys: ['title'],
    distance: 0,
    threshold: 0,
    useExtendedSearch: true,
  }), [memberships])

  React.useEffect(() => {
    (async () => {
      await getMemberships()
      setLoading(false)
    })()
  }, [getMemberships])


  React.useEffect(() => {
    if (!searchVal) {
      setSearchedMemberships(Object.values(memberships))
      return
    }

    const searchResults = fuse.search(searchVal).map((r) => r.item)

    setSearchedMemberships(searchResults)
  }, [searchVal, memberships, fuse])

  const handleSearchChange = (newValue: string) => {
    setSearchVal(newValue)
  }

  const styles = useStyles()

  const handleRowClicked = (membershipId: string) => {
    navigate(`/memberships/${membershipId}`)
  }

  const handleAddMembershipClick = () => {
    navigate('/memberships/new');
  }

  const MembershipTable = () => {
    if (loading) {
      return (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )
    }
    return (
      <>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Membership</TableCell>
                <TableCell>Active Patrons</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableContainer}>
              {searchedMemberships.map((membership) => {
                return (
                  <TableRow hover className={styles.row} key={membership.id} onClick={() => handleRowClicked(membership.uuid)}>
                    <TableCell>
                      <strong>{membership.title}</strong>
                    </TableCell>
                    <TableCell>{membership.activeSubscriptionCount}</TableCell>
                    <TableCell>{membership.status ? <Pill value={membership.status} color={['live', 'hidden'].includes(membership.status) ? 'green' : 'red'} /> : `-`}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  if (!searchedMemberships) {
    return (
      <Box mt={25} className={styles.buttonContainer}>
        <Typography mb={3} variant="h6">You don't have any memberships yet</Typography>
        <Button className={styles.addButton} onClick={handleAddMembershipClick}>Add Membership +</Button>
      </Box>
    )
  }

  return (
    <Box className={styles.container}>
      <Box mb={2} className={styles.controlsContainer}>
        <Box mr={2} className={styles.searchContainer}>
          <SearchBar fullWidth value={searchVal} onChange={handleSearchChange} />
        </Box>
      </Box>
      <MembershipTable />
    </Box>
  )
}

const useStyles = makeStyles({
  exportContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableContainer: {
    height: '100%',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  leftMenuContainer: {
    width: 300,
    height: '100%',
    borderRight: `2px solid ${colors.GREY_6}`
  },
  closeButton: {
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "100%",
    background: colors.GREY_2,
    cursor: 'pointer',
  },
  addButton: {
    width: 200,
  },
  closeContainer: {
    position: 'relative',
    background: colors.STITCH_BLUE,
    color: colors.WHITE,
    display: 'flex',
    borderTopLeftRadius: 20,
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchContainer: {
    width: '40%',
    flex: '0 1 auto',
  },
  filtersContainer: {
    display: 'flex',
  },
  boldText: {
    fontWeight: 'bold',
  },
  row: {
    cursor: 'pointer',
  },
  mrr: {
    color: colors.DARK_GREEN,
    fontWeight: 'bold',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200
  }
})
