import React, { FunctionComponent, useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Box,
  TextField,
  CircularProgress,
  Typography,
  FormControlLabel,
  Switch,
  Skeleton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import { DateRangePicker } from '@material-ui/lab'
import AdapterDayjs from '@material-ui/lab/AdapterDayjs'
import dayjs from 'dayjs'
import colors from '../../colors'
import axios from '../../utils/axios'
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Table from '../../components/Table'
import moment from 'moment'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface SalesReferral {
  employeeName: string;
  employeeId: string;
  createdAt: Date;
  id: string;
  invoiceId: string;
  organizationId: number;
  patronageEmail: string;
  patronageId: number;
  patronageName: string;
  saleAmount: number;
  stripeDescription: string;
}

const SalesAttributions: FunctionComponent = () => {
  const styles = useStyles()
  const [loading, setLoading] = useState<boolean>(true)

  const [salesReferrals, setSalesReferrals] = useState<SalesReferral[]>([])

  // Default the date to the days this month
  const [dates, setDates] = React.useState([dayjs(new Date(dayjs().year(), dayjs().month(), 1)), dayjs()])

  const [salesAttributionSettingEnabled, setSalesAttributionSettingEnabled] = useState<boolean | null>(null)

  useEffect(() => {
    if (!dates[0] || !dates[1]) { return }
    (async () => {
      setLoading(true)

      const settingsResponse = await axios.get('/organization/settings')
      setSalesAttributionSettingEnabled(settingsResponse.data.collectingEmployeeReferrals)

      const resp = await axios.get('/employee_referrals', {
        params: {
          dates: dates.map((d) => d.format())
        }
      })

      setSalesReferrals(resp.data)

      setLoading(false)
    })()
  }, [dates])

  const Loading: FunctionComponent = () => (
    <Box className={styles.progressContainer}>
      <CircularProgress />
    </Box>
  )

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleEmployeeReferralsSetting(event.target.checked)
  }

  const toggleEmployeeReferralsSetting = async (on: boolean) => {
    const settingsResponse = await axios.post('/organization/update_employee_referrals_setting', { on })
    setSalesAttributionSettingEnabled(settingsResponse.data.collectingEmployeeReferrals)
  }

  const ToggleFeature: FunctionComponent = () => (
    <Box>
      <FormControlLabel
        className={salesAttributionSettingEnabled ? styles.referralCollectionEnabled : styles.referralCollectionDisabled}
        control={
          <Switch
            checked={!!salesAttributionSettingEnabled}
            onChange={handleToggleChange}
            color="success"
          />
        }
        label={salesAttributionSettingEnabled ? 'ON (Visible to your Patrons)' : 'OFF (Not visible to your Patrons)'}
      />
    </Box>
  )

  const ReferralChart: FunctionComponent = () => {
    if (!salesReferrals || !salesReferrals.length) {
      return (
        <Box>
          No Referrals during this period
        </Box>
      )
    }
    const labels = _.uniq(salesReferrals.map((d) => d.employeeName))
    const counts = labels.map((employeeName) => _.countBy(salesReferrals, (salesReferral) => salesReferral.employeeName)[employeeName])

    return (
      <Box p={2}>
        <Bar
          style={{ maxHeight: 300 }}
          options={{
            scales: {
              yAxes: {
                ticks: {
                  stepSize: 1,
                }
              },
            }
          }}
          data={{
            labels,
            datasets: [{
              data: counts,
              backgroundColor: colors.STITCH_BLUE,
              maxBarThickness: 100,
              label: `Sales Attributions: ${dates[0].format('MM/D/YYYY')} - ${dates[1].format('MM/D/YYYY')}`
            }]
          }}
        />
      </Box>

    )
  }

  const SalesTable = () => {
    return (
      <Table<SalesReferral>
        title={'Sales Attributions'}
        rows={Object.values(salesReferrals)}
        loading={loading}
        filters={[
          {
            title: 'Employee',
            matchingFunction: (s) => s.employeeName
          },
        ]}
        columns={[
          {
            label: 'Employee',
            cellFill: (s) => s.employeeName,
            searchable: true,
            searchFunction: (s) => s.employeeName,
            csv: true,
            sortValueGetter: (s) => s.employeeName,
          },
          {
            label: 'Date',
            cellFill: (s) => moment(s.createdAt).format('M/D/YYYY'),
            searchable: false,
            csv: true,
            sortValueGetter: (s) => s.createdAt,
          },
          {
            label: 'Patron Email',
            cellFill: (s) => s.patronageEmail,
            searchable: true,
            searchFunction: (s) => s.patronageEmail,
            csv: true,
            sortValueGetter: (s) => s.patronageEmail,
          },
          {
            label: 'Sale Description',
            cellFill: (s) => s.stripeDescription,
            searchable: true,
            csv: true,
            sortValueGetter: (s) => s.stripeDescription,
          }
        ]
        }
        handleRowClicked={(patron) => { alert(patron.id) }}
        id={'patrons'}
      />
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box p={2} className={styles.container}>
        <Box mb={4} className={styles.headingContainer}>
          <Box>
            <Typography variant={'h3'}>
              Sales Attributions
            </Typography>
            <Typography variant={'caption'}>
              This feature allows patrons to attribute their purchases to a<br />
              particular employee. See employees’ attributed purchases<br />
              below. A patron can attribute their purchase by selecting from a<br />
              list of non-archived employees.
            </Typography>
          </Box>
          {salesAttributionSettingEnabled === null ? <Skeleton /> : <ToggleFeature />}
        </Box>
        <Box>Reporting</Box>
        <Box mb={4} className={styles.controlsContainer}>
          <DateRangePicker
            calendars={1}
            value={[dates[0], dates[1]]}
            onChange={(newValue: any) => {
              setDates(newValue)
            }}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </Box>
        {loading ? <Loading /> : <ReferralChart />}
      </Box>
      <Box className={styles.tableContainer}>
        {salesReferrals?.length ? <SalesTable /> : null}
      </Box>
    </LocalizationProvider>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  referralCollectionDisabled: {
    marginTop: '15px',
    padding: '5px',
    paddingRight: '15px',
    justifyContent: 'center',
    borderRadius: '5px',
    backgroundColor: '#FFEBED',
    width: '350px'
  },
  referralCollectionEnabled: {
    marginTop: '15px',
    padding: '5px',
    paddingRight: '15px',
    justifyContent: 'center',
    borderRadius: '5px',
    backgroundColor: '#EFFAF4',
    width: '350px'
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableContainer: {
    width: '90%',
    marginLeft: '20px',
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
  },
})

export default SalesAttributions
