import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Subscription } from '../types'
import stitchAxios from '../utils/axios'
import { AxiosResponse } from 'axios'

type SubscriptionStore = {
  subscriptions: Record<string, Subscription>,
  addSubscriptions: Function,
  fetchSubscription: (id: string) => Promise<void>,
  getSubscriptionsForMembership: (membershipId: string) => Promise<AxiosResponse<Subscription[]>>,
  rehydrated: boolean,
  setRehydrated: Function
}

const createFunction = (set: Function, _: Function): SubscriptionStore => ({
  subscriptions: {},
  addSubscriptions: (subscriptions: Subscription[]) => {
    set((state: SubscriptionStore) => ({
      ...state,
      subscriptions: {
        ...state.subscriptions,
        ...Object.fromEntries(subscriptions.map((sub) => ([sub.id, sub])))
      }
    }))
  },
  fetchSubscription: async (_: string) => {
  },
  getSubscriptionsForMembership: async (membershipId: string) => {
    return await stitchAxios.get(`/memberships/${membershipId}/subscriptions`)
  },
  rehydrated: false,
  setRehydrated: (isRehydrated: boolean) => set({ rehydrated: isRehydrated })
})

export const useSubscriptionStore = create<SubscriptionStore>()(devtools(createFunction))

export default useSubscriptionStore
