import { FunctionComponent } from 'react'
import { PatronBenefit } from '../types'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../colors'
import { SPACING } from '../constants'

interface Props {
  benefit: PatronBenefit;
};

const BenefitCard: FunctionComponent<Props> = ({ benefit }) => {
  const styles = useStyles()
  return (
    <Box className={styles.container}>
      <strong>{benefit.name}</strong>
      <Button color={'success'}>
        Redeem
      </Button>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: SPACING / 2,
    padding: SPACING * 2,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderTop: `${SPACING / 2}px solid ${colors.BRIGHT_BLUE}`,
    display: 'flex',
    flexDirection: 'column',
  }
}))

export default BenefitCard
