import Submenu from '../../components/Submenu'
import { Users, BarChart2 } from 'react-feather'

export default function Employees() {
  return (
    <Submenu
      basePath={"/employees"}
      items={[
        { icon: Users, title: 'Manage Employees', subPath: "", id: 'manageEmployees' },
        { icon: BarChart2, title: 'Sales Attributions', subPath: "/sales_attributions", id: 'sales_attributions' }
      ]}
    />
  )
}