import { FunctionComponent } from 'react'
import { X, ChevronRight } from 'react-feather'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import colors from '../../colors'

type BreadCrumbType = {
  title: string,
  linkPath: string,
  loading?: boolean,
}


type TopBarProps = {
  breadCrumbs: BreadCrumbType[],
  closePath: string
}

const BreadCrumb: FunctionComponent<{ crumbs: BreadCrumbType[]}> = ({ crumbs }) => {
  const styles = useStyles()
  return (
    <Box className={styles.breadCrumbContainer}>
      <Link to={'/'}>
        <Box mr={2} ml={2} className={styles.crumbTitle}>
          <img alt={"Stitch Logo"} style={{ marginTop: 2, width: 'auto', height: 45 }}src="/bare_logo_blue.png" />
        </Box>
      </Link>
      <ChevronRight />
      { crumbs.map((crumb, index) => {
        // Do not render the last crumb
        if (index === crumbs.length - 1) return null

        return (
          <Box className={styles.breadCrumbContainer} key={crumb.title}>
            <Link to={crumb.linkPath}>
              <Box mr={2} ml={2} className={styles.crumbTitle}>
                {crumb.title}
              </Box>
            </Link>
            <ChevronRight />
          </Box>
        )
      }) }
      <Box ml={2} className={styles.currentBreadCrumb}>
        {crumbs[crumbs.length - 1].title}
      </Box>
    </Box>
  )
}

const CloseButton: FunctionComponent<{ closePath: string }> = ({ closePath }) => {
  const styles = useStyles()
  return (
    <Link to={closePath}>
      <Box className={styles.closeButtonCircle}>
        <X size={20} />
      </Box>
    </Link>
  )
}

const TopBar: FunctionComponent<TopBarProps> = ({ breadCrumbs, closePath }) => {
  const styles = useStyles()
  return (
    <Box p={2} className={styles.container}>
      <Box className={styles.breadCrumbContainer}>
        <Box mr={4}>
          <CloseButton closePath={closePath} />
        </Box>
        <BreadCrumb crumbs={breadCrumbs} />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  currentBreadCrumb: {
    fontWeight: 'bold'
  },
  breadCrumbContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    borderTop: `8px solid ${colors.STITCH_BLUE}`,
    background: colors.GREY_7,
    display: 'flex',
    flex: '0 0 70px',
    justifyContent: 'space-between',
  },
  closeButtonCircle: {
    display: 'flex',
    height: 30,
    width: 30,
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.GREY_5,
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
    }
  },
  crumbTitle: {
    '&:hover': {
      textDecoration: 'underline',
    }
  }
})
export default TopBar

