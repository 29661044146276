import { useAuthStore } from '../../stores/auth'
import colors from '../../colors'
import { Box, Button, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TextField } from 'formik-material-ui'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

const LoginForm = () => {
  const styles = useStyles()
  const { login } = useAuthStore()

  const validationSchema = Yup.object({
    email: Yup.string().required('Required').email(),
    password: Yup.string().required('Required'),
  })
  const defaultErrorMessage = (
    <>
      Somethine went wrong. If this problem persists, please contact Stitch at &nbsp;
      <a href={`mailto:help@stitch.vip`} className={styles.emailLink}>help&amp;stitch.vip</a>
    </>
  )

  const onSubmit = async ({ email, password }: { email: string, password: string }) => {
    try {
      const [success, message] = await login(email, password)
      if (!success) {
        toast.error(message)
      }
    } catch (e) {
      toast.error(defaultErrorMessage)
    }
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        return (
          <Box>
            <Typography variant={'h3'}>Login</Typography>
            <Form>
              <Box className={styles.fieldsContainer}>
                <Field
                  autoFocus
                  fullWidth
                  autoCapitalize="none"
                  type="email"
                  label="Email"
                  component={TextField}
                  name="email"
                />
                <Field
                  autoCapitalize="none"
                  fullWidth
                  type="password"
                  label="Password"
                  component={TextField}
                  name="password"
                />
                <Link className={styles.link} to={'/password_reset/request'}>
                  Forgot your password?
                </Link>
                <Box mt={1} className={styles.buttonContainer}>
                  <Button type='submit' disabled={formikProps.isSubmitting}>
                    {formikProps.isSubmitting ? <CircularProgress size={16} style={{ marginRight: 8 }} /> : null}
                    Sign In
                  </Button>
                </Box>
              </Box>
            </Form>
          </Box>
        )
      }}
    </Formik>
  )
}

const useStyles = makeStyles({
  link: {
    color: colors.BRIGHT_BLUE
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    maxWidth: '90vw',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  emailLink: {
    textDecoration: 'underline',
  }
})

export default LoginForm
