import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { SortState } from '../types'

// Define the TableState type with a generic parameter T
type TableState<T> = {
  searchTerm: string,
  sortState: SortState<T>,
  indexPageNumber: number,
  indexRowsPerPage: number,
}

type Store = {
  tableStates: Record<string, TableState<any>>, // I'm not sure how to do this without using any...
  setSearchTerm: (storeId: string, searchTerm: string) => void,
  setIndexPageNumber: (storeId: string, indexPageNumber: number) => void,
  setSortState: <T>(storeId: string, sortState: SortState<T>) => void,
  setIndexRowsPerPage: (storeId: string, indexRowsPerPage: number) => void,
  setStoreState: <T>(storeId: string, state: TableState<T> | Partial<TableState<T>>) => void,
  initializeTableState: <T>(storeId: string, defaultSortState: SortState<T>) => TableState<T>,
}

// Function to generate a blank table state
const generateBlankTableState = <T>(defaultSortState: SortState<T>): TableState<T> => ({
  searchTerm: '',
  sortState: defaultSortState,
  indexPageNumber: 0,
  indexRowsPerPage: 10,
})

// Create function for zustand store
const createFunction = (set: (partial: Partial<Store> | ((state: Store) => Partial<Store>), replace?: boolean) => void, get: () => Store): Store => ({
  tableStates: {},
  setSearchTerm: (storeId: string, searchTerm: string) => {
    set((state) => ({
      tableStates: {
        ...state.tableStates,
        [storeId]: {
          ...state.tableStates[storeId],
          searchTerm,
        }
      }
    }));
  },
  setStoreState: <T>(storeId: string, state: TableState<T> | Partial<TableState<T>>) => {
    set((s) => ({
      tableStates: {
        ...s.tableStates,
        [storeId]: {
          ...s.tableStates[storeId],
          ...state,
        }
      }
    }));
  },
  setIndexPageNumber: (storeId: string, indexPageNumber: number) => {
    set((state) => ({
      tableStates: {
        ...state.tableStates,
        [storeId]: {
          ...state.tableStates[storeId],
          indexPageNumber,
        }
      }
    }));
  },
  setSortState: <T>(storeId: string, sortState: SortState<T>) => {
    const newState = get().tableStates[storeId] || generateBlankTableState<T>(sortState)
    set((state) => ({
      tableStates: {
        ...state.tableStates,
        [storeId]: {
          ...newState,
          sortState: sortState,
        }
      }
    }));
  },
  setIndexRowsPerPage: (storeId: string, indexRowsPerPage: number) => {
    set((state) => ({
      tableStates: {
        ...state.tableStates,
        [storeId]: {
          ...state.tableStates[storeId],
          indexRowsPerPage,
        }
      }
    }));
  },
  initializeTableState: <T>(storeId: string, defaultSortState: SortState<T>) => {
    let state = get().tableStates[storeId]
    if (state) {
      return state
    } else {
      state = generateBlankTableState<T>(defaultSortState)
    }

    set((store) => ({
      tableStates: {
        ...store.tableStates,
        [storeId]: state as TableState<T>,
      }
    }));
    return state
  },
});

export const useTableStore = create<Store>()(
  persist(
    createFunction,
    { name: 'table-store' },
  )
);

export default useTableStore;