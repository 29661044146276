import { useLocation } from 'react-router-dom'
import { MENU_ITEMS } from '../constants'
import { MenuItem } from '../types'

const useCurrentAppLocation = (): MenuItem => {
  const location = useLocation()

  if (!location || !location.pathname) {
    throw new Error(`useCurrentLocation was called, but no location was found`)
  }


  const appLocation = Object.values(MENU_ITEMS).find((menuItem) => location.pathname.startsWith(menuItem.path))

  if (!appLocation) {
    throw new Error(`The pathname ${location.pathname} does not match any MENU_ITEMS`)
  }
  return appLocation
}

export default useCurrentAppLocation
