import { FunctionComponent } from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import colors from '../../colors'

type Props = {
  size?: number,
  hoverText?: string,
}

const Grey: FunctionComponent<Props> = ({ size = 24, hoverText = '' }) => {
  const styles = useStyles()
  return (
    <Tooltip title={hoverText}>
      <Box className={styles.container}style={{ width: size, height: size }}>
      </Box>
    </Tooltip>
  )
}

const useStyles = makeStyles({
  container: {
    background: colors.PALE_BLUE,
    border: `2px solid ${colors.BRIGHT_BLUE}`,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export default Grey
