import { useState, useEffect } from 'react';
import { Patron } from '../../../src/types';
import { Box } from '@material-ui/core';
import Table from '../../components/Table';
import { makeStyles } from '@material-ui/styles';
import colors from '../../colors';
import usePatronStore from '../../stores/patron';
import { useNavigate } from 'react-router-dom';

export default function Page() {
  const { patrons, getPatrons } = usePatronStore()
  const styles = useStyles()
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      await getPatrons()
      setLoading(false)
    })()
  }, [getPatrons, setLoading])


  const PatronsTable = () => {
    return (
      <Table<Patron>
        title={'Patrons'}
        rows={Object.values(patrons)}
        loading={loading}
        filters={[]}
        columns={[
          {
            label: 'First Name',
            cellFill: (p) => p.firstName,
            searchable: true,
            searchFunction: (p) => String(p.firstName),
            csv: true,
            sortValueGetter: (p) => String(p.firstName),
          },
          {
            label: 'Last Name',
            cellFill: (p: Patron) => p.lastName,
            searchable: true,
            searchFunction: (p) => String(p.lastName),
            csv: true,
            sortValueGetter: (p) => String(p.lastName),
          },
          {
            label: 'Email',
            cellFill: (p) => p.email,
            searchable: true,
            searchFunction: (p) => String(p.email),
            hide: false,
            csv: true,
            sortValueGetter: (p) => String(p.email),
          },
          {
            label: 'Phone',
            cellFill: (p) => p.phone,
            csv: true,
            sortValueGetter: (p) => String(p.phone),
          }
        ]
        }
        handleRowClicked={(patron) => { navigate(`/patrons/${patron.id}`) }}
        id={'patrons'}
      />
    )
  }

  return (
    <Box className={styles.container}>
      <PatronsTable />
    </Box>
  )
}

const useStyles = makeStyles({
  exportContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  tableContainer: {
    height: '100%',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  leftMenuContainer: {
    width: 300,
    height: '100%',
    borderRight: `2px solid ${colors.GREY_6}`
  },
  closeButton: {
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "100%",
    background: colors.GREY_2,
    cursor: 'pointer',
  },
  closeContainer: {
    position: 'relative',
    background: colors.STITCH_BLUE,
    color: colors.WHITE,
    display: 'flex',
    borderTopLeftRadius: 20,
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchContainer: {
    width: '40%',
    flex: '0 1 auto',
  },
  boldText: {
    fontWeight: 'bold',
  },
  row: {
    cursor: 'pointer',
  },
  mrr: {
    color: colors.DARK_GREEN,
    fontWeight: 'bold',
  },
})
