import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import { PatronBenefit } from '../types'
import axios from '../utils/axios'
import useBenefitCycleStore from './benefitCycle'

type Store = {
  patronageBenefits: Record<string, PatronBenefit>,
  addPatronageBenefits: (patronageBenefits: PatronBenefit[]) => void
  redeemBenefit: (id: string) => Promise<void>,
  unredeemBenefit: (id: string) => Promise<void>,
  updatePatronageBenefit: (patronageBenefit: PatronBenefit) => Promise<void>,
  rehydrated: boolean,
  setRehydrated: Function
  destroyBenefit: (patronageBenefitId: string, benefitCycleId: string) => Promise<void>,
}

const createFunction = (set: Function, get: Function): Store => ({
  patronageBenefits: {},
  addPatronageBenefits: (patronageBenefits: PatronBenefit[]) => {
    const patronageBenefitsObj = Object.fromEntries(patronageBenefits.map((p) => ([p.uuid, p])))

    set((store: Store) => ({
      ...store,
      patronageBenefits: {
        ...store.patronageBenefits,
        ...patronageBenefitsObj
      }
    }))
  },
  redeemBenefit: async (id: string) => {
    const response = await axios.post(`/patronage_benefits/${id}/redeem`)
    const patronageBenefit = response.data
    set((state: Store) => ({
      ...state,
      patronageBenefits: {
        ...state.patronageBenefits,
        [id]: {
          ...patronageBenefit
        }
      }
    }))
  },
  unredeemBenefit: async (id: string) => {
    const response = await axios.post(`/patronage_benefits/${id}/unredeem`)
    const patronageBenefit = response.data
    set((state: Store) => ({
      ...state,
      patronageBenefits: {
        ...state.patronageBenefits,
        [id]: {
          ...patronageBenefit
        }
      }
    }))
  },
  destroyBenefit: async (patronageBenefitId: string, benefitCycleId: string) => {
    await axios.delete(`/patronage_benefits/${patronageBenefitId}`)
    const benefitCycle = { ...useBenefitCycleStore.getState().benefitCycles[benefitCycleId] }
    const patronageBenefits = { ...get().patronageBenefits }

    delete patronageBenefits[patronageBenefitId]
    const patronageBenefitIds = benefitCycle.patronageBenefitIds.filter((id: string) => id !== patronageBenefitId)

    useBenefitCycleStore.getState().removePatronageBenefit(patronageBenefitId, benefitCycleId)
    useBenefitCycleStore.setState((state) => ({
      ...state,
      benefitCycles:
        { ...state.benefitCycles, [benefitCycle.id]: { ...state.benefitCycles[benefitCycleId], patronageBenefitIds } }
    }))

    set((store: Store) => ({
      ...store,
      patronageBenefits
    }))
  },
  updatePatronageBenefit: async (patronageBenefit: PatronBenefit) => {
    const resp = await axios.post(`/benefit_cycles/${patronageBenefit.id}`, {
      ...patronageBenefit
    })
    const pb = resp.data

    set((store: Store) => ({
      ...store,
      patronageBenefits: {
        ...store.patronageBenefits,
        [pb.id]: pb
      }
    }))
  },
  rehydrated: false,
  setRehydrated: (isRehydrated: boolean) => set({ rehydrated: isRehydrated })
})

export const usePatronSubscriptionStore = create(devtools(persist(createFunction, {
  name: 'stitchPatronageBenefits',
  onRehydrateStorage: (_: Store) => {
    return ((state?: Store | undefined, error?: unknown): void => {
      if (!state) {
        throw error
      }

      state.setRehydrated(true)
    })
  },
})))

export default usePatronSubscriptionStore
