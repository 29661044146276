import { FunctionComponent, useState, useEffect, } from 'react'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import BenefitCard from '../../components/BenefitCard'
import { makeStyles } from '@material-ui/styles'
import GreenCheck from '../../components/CircleIcons/Green'
import RedX from '../../components/CircleIcons/Red'
import GreyDash from '../../components/CircleIcons/Grey'
import { SPACING } from '../../constants'
import axios from '../../utils/axios'
import { Subscription } from '../../types'
import colors from '../../colors'

type Colors = { dark: string, light: string };
const App: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [subscription, setSubscription] = useState<Subscription>()
  let statusColors: Colors = {
    dark: colors.GREY_1,
    light: colors.GREY_6
  };
  let Icon = GreyDash;

  switch (subscription && subscription.status) {
    case 'Active':
    case 'Pending Cancel':
      Icon = GreenCheck;
      statusColors.dark = colors.DARK_GREEN;
      statusColors.light = colors.PALE_GREEN;
      break;
    case 'Failed Payment':
    case 'Canceled':
      Icon = RedX;
      statusColors.dark = colors.DARK_RED;
      statusColors.light = colors.PALE_RED;
      break;
  }

  const styles = useStyles(statusColors)
  useEffect(() => {
    (async () => {
      if (!id) { return }
      const response = await axios.get(`/lite/subscriptions/${id}`)
      setSubscription(response.data)
      setLoading(false)
    })()
  }, [id])

  if (loading) {
    return (
      <Box className={styles.loadingContainer}>
        <CircularProgress />
      </Box>
    )
  }

  if (!subscription) {
    return (
      <Box className={styles.loadingContainer}>
        <CircularProgress />
      </Box>
    )
  }

  console.log(subscription)
  return (
    <Box className={styles.mainContainer}>
      <Box className={styles.header}>
        <Box className={styles.nameContainer}>
          <Box mb={1}>
            <Typography variant={"h3"}>{subscription.patronName}</Typography>
          </Box>
          <Box mb={1} className={styles.row}>
            <Box mr={1}>
              <Icon />
            </Box>
            {subscription.status}
          </Box>
          <Box>{subscription.renewalText}</Box>
        </Box>
      </Box>
      <Box p={2} className={styles.benefitsContainer}>
        <Box mb={1}>
          <Typography variant={'h4'}>Benefits</Typography>
        </Box>
        { subscription.benefits && subscription.benefits.map((b) => (
          <Box mb={2}>
            <BenefitCard key={b.id} benefit={b} />
          </Box>
        ))
        }
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  benefitsContainer: {
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    borderTopRadius: 12,
    padding: SPACING * 2,
    height: 175,
    backgroundColor: (colors: Colors) => colors.light,
    width: '100%',
    borderBottom: (colors: Colors) => `4px solid ${colors.dark}`,
    alignItems: 'flex-end',
    display: 'flex',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  mainContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  bodyContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  innerContainer: {
    padding: SPACING * 4,
    flex: 1,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    }
  }
}))

export default App;

